.offices-search {
    @extend %content-contained;
}

/* Search UI */
.offices-search__ui {
    display: block;
}

.offices-ui__row {
    display: flex;
    margin-top: 80px;

    &:first-child {
        margin-top: 0;
    }

    @include small-breakpoint {
        flex-direction: column;
        margin-top: 0;
    }
}

.offices-ui__column {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;

    &:first-of-type {
        margin-right: 40px;
    }

    @include small-breakpoint {
        flex-basis: auto;
        margin-bottom: 50px;
    }
}

.offices-ui__title,
.offices-ui__label {
    font-size: $font-size-med-2;
    line-height: $font-size-med-2-line;
}

.offices-ui__title {
    margin-bottom: 30px;
}

.offices-ui__label {
    font-weight: 700;
    text-transform: none;
    color: $color-text;
    margin-bottom: 10px;
}

.offices-ui__help-text {
    font-size: $body-cdmsmith-font-size;
    line-height: $body-cdmsmith-line;
    font-weight: 400;
    text-transform: none;
    color: $color-text;
    margin-bottom: 20px;
}

.offices-ui__select-list__wrapper {
    display: block;

    select {
        width: 100%;
        min-width: 100px;
    }
}

.offices-ui__statement {
    margin-top: 30px;
    margin-bottom: 30px;
}

.offices-ui__results-wrapper {
    width: 100%;
}

.offices-ui__results-list {
    display: flex;
    flex-wrap: wrap;
}

/* Single office contact info */
.office__section {
    display: block;
    font-size: $body-cdmsmith-font-size;
    line-height: $body-cdmsmith-line;
}

.office__number-label,
.office__email-label {
    font-weight: 700;
    margin-right: 5px;
}

/* Special Offices (headquarters & nearest) */
.offices-ui__row--special-offices {
    .office__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .office__section {
        flex-grow: 1;
        flex-shrink: 1;

        &:first-of-type {
            margin-right: 10px;
        }

        @include small-breakpoint {
            flex-basis: 100%;
        }
    }

    .office__name {
        display: none;
    }
}

/* Searched Offices */
.offices-ui__row--searched-offices {
    .offices-ui__label {
        margin-bottom: 30px;
    }

    .office__wrapper {
        display: none;
    }

    .office__wrapper.is-a-result {
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: 20px;
        margin-bottom: 40px;

        @include large-breakpoint {
            flex-basis: calc(100% / 3);
        }

        @include medium-breakpoint {
            flex-basis: calc(50% - 20px);
        }

        @include small-breakpoint {
            flex-basis: 100%;

            .office__section {
                padding-right: 0;
            }
        }
    }

    .office__name {
        font-weight: 700;
        margin-bottom: 10px;
    }

    .office__email a {
        font-weight: 400;
    }
}

p {
    font-size: $body-cdmsmith-font-size !important;
    line-height: $body-cdmsmith-line !important;
    @extend %limit-line-width;
    margin-bottom: 16px;
    color: $color-text;

    @include small-breakpoint {
        font-size: #{$body-cdmsmith-font-size * 0.8};
        line-height: #{$body-cdmsmith-line * 0.8};
    }
}

.paragraph--small {
    font-size: 15px;

    @include small-breakpoint {
        font-size: 16px;
    }
}

.paragraph--splash {
    font-family: $font-cdmsmith;
    font-size: 21px;
    color: $color-text-subtle;

    a {
        color: $color-text-subtle;
    }
}

blockquote,
cite {
    display: block;
    font-style: inherit;
}

/* More info about @page: https://drafts.csswg.org/css-page-3 */
@page {
    margin: 1in 0.5in 0.5in;
    orphans: 4;
    widows: 3;
    size: A4; // other options: 8.5in 11in, auto (="responsive")
}

html,
body
{
    font-size: $body-cdmsmith-font-size !important;
    line-height: $body-cdmsmith-line !important;
    background-color: #FFF;
    height: auto !important;
    margin: 0;
    max-width: $print-max-width !important;
    padding: 0;
    width: 100% !important;
}

.print-only {
    display: block;
}

.profiler-results {
  display: none;
}

.print-2-col {
  position: static !important;

  .split-container__inner--70 {
    margin-right: 0 !important;
    max-width: calc(65% - 20px);
    float: left;
  }

  .split-container__inner--30 {
    margin-left: 0 !important;
    width: auto;
    max-width: calc(35% - 20px);
    padding-left: 20px !important;
    float: left;
    hyphens:none;
  }
}

/* Hide accessibility stuff */
.skip-link,
.screen-reader-only {
    display: none;
}

/* Excerpt from the HTML5 Boilerplate print style sheet */
*,
*:before,
*:after,
*:first-letter,
p:first-line,
div:first-line,
blockquote:first-line,
li:first-line {
    background: transparent !important;
    color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important;
}

/*  TODO (Once we have an h1):
    Print H1 content at top of page
    How-to @ https://www.smashingmagazine.com/2015/01/designing-for-print-with-css/
*/

/*  TODO (Nice to have ONLY!);
    Print a QR code at the bottom of the document that links to the CDM site
    How-to: https://www.smashingmagazine.com/2013/03/tips-and-tricks-for-print-style-sheets/#print-qr-codes-for-easy-url-references
*/

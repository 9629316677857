/*------------------------------------*\
    #RETINA QUERY MIXINS
    usage:  @include retina-screen {
                font-size: 18px;
            }
\*------------------------------------*/

/* 1.5 dpr and above */
@mixin retina-screen {
    @media
    (-webkit-min-device-pixel-ratio: 1.5),
    (min-resolution: 144dpi) {
        @content;
    }
}

/* 1.49 dpr and below */
@mixin not-retina-screen {
    @media
    (-webkit-max-device-pixel-ratio: 1.49),
    (max-resolution: 143dpi) {
        @content;
    }
}
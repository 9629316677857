$search-item-subtitle-color: $color-gray-87;

.search-item {
	border-bottom: 1px solid $color-gray-15;
	margin-bottom: 30px;
	padding-bottom: 30px;
}



.search-item__title {
	font-size: $font-size-med;
	line-height: $font-size-med-line;
	margin-bottom: 5px;

	a {
		border: none;
	}
}

@media only screen and (max-width:799px) {
    .search-item__title{

        font-size: $font-size-small-3 !important;
        line-height: $font-size-small-3-line !important;

    }

		.search-item__authors{
		font-size: $body-cdmsmith-font-size;
		line-height:$body-cdmsmith-line;
		}

		.featured-person__bio {
        font-size: $body-cdmsmith-font-size;
        line-height: $body-cdmsmith-line;
    }
}

.search-item__meta {
	margin-bottom: 5px;
}

.search-item__image-link {
	border: none;

	&:hover {
		border: none;
	}
}

.search-item__image-container {
	@include aspect-ratio(1, 1);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: inline-block;
	margin-right: 25px;
	width: 150px;

	@include small-breakpoint {
		display: none;
	}
}

.search-item__image {
	max-width: 100%;
}

.search-item__content {
	display: inline-block;
	vertical-align: top;
	width: calc(75% - 25px);

	@include small-breakpoint {
		width: 100%;
	}
}

.search-item__content-type {
	color: $color-gray-40;
	font-size: $font-size-small;
	font-weight: 700;
	text-transform: uppercase;
}

.search-item__subtitle {
	color: $search-item-subtitle-color;
	font-family: $font-cdmsmith;
	font-size: $body-cdmsmith-font-size;
	font-style: italic;
	font-weight: 400;
	line-height: $body-cdmsmith-line;
	margin-bottom: 15px;
}

.search-item__body {
	font-size: $body-cdmsmith-font-size;
	line-height: $body-cdmsmith-line;
}

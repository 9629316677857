/*  Prink link targets unless they're anchor links */
a {
    font-weight: 700;
    color: #000;
    text-decoration: none;

    &[href^="#"]:after {
       content: "" !important;
    }

    &:after {
        content: " <" $domain attr(href) ">" !important;
        font-family: $font-cdmsmith !important;
        font-size: 12px !important;
        line-height: 14px !important;
        margin-left: 10px !important;
        word-wrap: break-word !important;
        text-decoration: none !important;
        font-weight: 400 !important;
        text-transform: none !important;
    }
}

/* Underline links in paragraphs */
p a {
    text-decoration: underline;
}

/* Style buttons more or less like links */
.btn {
    min-height: auto !important;
    padding: 0 !important;
    letter-spacing: 0 !important;
}

button {
    display: none !important;
}

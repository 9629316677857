main > .section,
main > .container {
    margin-bottom: 0;
}

.section {
    max-width: $print-max-width;
    padding: 20px 0 !important;
}

/* Remove dots on section titles */
.section-title {
    margin-bottom: 0;
    &__heading {
        padding-left: 0;
    }
    &__block {
        display: none;
    }
}

/*  Don't show alt backgrounds */
.has-alt-background {
    background-color: #FFF !important;
}

/* Replace component backgrounds with outlines */
.has-background {
    background-color: #FFF !important;
    * {
        background-color: transparent !important;
    }
}

.is-full-width {
    margin-left: 0 !important;
    width: 100% !important;
}

/* Normalize rail containers */
.rail-container{
    &__title {
        padding: 10px 0;
    }
    &__content.has-alt-background {
        padding: 0;
    }
}


.split-container {
    float: none!important;

    .split-container__inner {
        display: block !important;
    }
}

.split-container__inner--70,
.split-container__inner--30 {
  width: 100%;
}

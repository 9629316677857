.area-of-focus__title {
    margin-bottom: 18px;
    font-family: $font-cdmsmith-light-sans;
    font-size: $font-size-med-2;
    line-height: $font-size-med-2-line;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 1px;
}

@media only screen and (max-width:799px) {
    .area-of-focus__title{
        font-size: $font-size-med !important;
        line-height: $font-size-med-line !important;
    }
}

.area-of-focus__list {
    list-style: none;
}

.area-of-focus__list-item {
    margin-bottom: 20px;
    font-size: $font-size-small-2;
    line-height: $font-size-small-2-line;

    a {
        @extend %link-on-hover-only;
        color: $color-brand-light-blue-font-rd;
        border-bottom: 1px solid;
        &:hover {
      		color: $color-brand-light-blue-acc;
      		border-bottom: 3px solid $color-brand-light-blue-acc !important;
      	}
    }

    .btn{
    	color: $color-white;
    	background-color: $color-brand-light-blue-acc;
    	&:hover {
    		color: $color-brand-light-blue-acc;
    		background-color: $color-white;
    		outline: 2px solid $color-brand-light-blue-acc;
        border-bottom: 0px;
    	}
    }
}

@media only screen and (max-width:799px) {
    .area-of-focus__title{
        font-size: $font-size-med;
        line-height: $font-size-med-line;
    }
    .area-of-focus__list-item {
        margin-bottom: 20px;
        font-size: $body-cdmsmith-font-size;
        line-height: $body-cdmsmith-line;
    }


}

.split-container__inner--30 .area-of-focus__title {
    font-size: $font-size-small-3;
    line-height: $font-size-small-3-line;
}

/* component wraps into 2 columns in 50-50 container */
.split-container__inner--50 .area-of-focus__list {
    display: flex;
    flex-wrap: wrap;

    > * {
        width: calc(50% - 10px);
    }

    > *:nth-child(odd) {
        margin-right: 20px;
    }

    > *:only-child {
        width: 100%;
        margin-right: 0;
    }

    @include small-breakpoint {
        > *,
        > *:nth-child(odd),
        > *:only-child {
            width: 100%;
            margin-right: 0;
        }
    }
}

html,
body {
    font-family: $font-cdmsmith;
    font-size: 1em;
    line-height: 1.4;
}

/*  Don't print headings at the end of a page */
h1, h2, h3, h4, h5 {
    page-break-after : avoid;
    font-family: $font-cdmsmith;
    orphans: 3;
    widows: 3;
}

h1 {
    font-size: $font-size-large-2-m;
    line-height: $font-size-large-2-m-line;
}

h2 {
    font-size: $font-size-large-m;
    line-height: $font-size-large-m-line;
}

h3 {
    font-size: $font-size-med-2;
    line-height: $font-size-med-2-line;
}

h4 {
    font-size: $font-size-small-3;
    line-height: $font-size-small-3;
}

h5, h6 {
    font-size: $body-cdmsmith-font-size;
    line-height: $body-cdmsmith-font-size;
}

p {
    font-size: $body-cdmsmith-font-size;
    line-height: $body-cdmsmith-line;
    font-family: $font-cdmsmith;
    margin-bottom: 15px;
    orphans: 3;
    widows: 4;
}

.heading--section-title {
    @extend %content-contained;
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 5px;
    margin-bottom: 36px;
    font-family: $font-cdmsmith;
    font-size: $font-size-large-m;
    font-style: italic;
    font-weight: normal;
    line-height: $font-size-large-m-line;
    color: $color-text;

    &::after {
        position: absolute;
        top: 50%;
        display: inline-block;
        width: 100%;
        height: 1px;
        margin-left: 15px;
        background-color: $section-title-line;
        content: "";
    }
}

.heading--small {
    font-size: $body-cdmsmith-font-size;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.heading--small-subtle {
    @extend .heading--small;
    color: $color-text-subtle;
}

/*  Print title attribute after any abbreviations */
abbr[title]:after {
    content: " (" attr(title) ")";
}

input {
    height: 41px;
    min-width: 300px;
    padding: 10px 15px;
    font-size: 16px;
    background-color: $color-white;
    border: 1px solid $color-gray-15;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $color-gray-55;
        opacity: 1;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        color: $color-gray-55;
        opacity: 1;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        color: $color-gray-55;
        opacity: 1;
    }

    &:-moz-placeholder { /* Firefox 18- */
        color: $color-gray-55;
        opacity: 1;
    }
}

.select-list,
.data-list {
    position: relative;
    display: inline-block;
    cursor: pointer;

    &::before {
        position: absolute;
        right: 40px;
        height: 100%;
        pointer-events: none;
        border-left: 1px solid $color-gray-15;
        content: "";
    }

    &::after {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 0;
        height: 0;
        margin-top: -3px;
        pointer-events: none;
        border-color: $color-brand-green transparent;
        border-style: solid;
        border-width: 6px 6px 0;
        content: "";
    }

    input::-webkit-calendar-picker-indicator {
        display: none; // turns off browser-styled arrow for datalist
    }

    select {
        @extend input;
        padding: 10px 55px 10px 15px;
        appearance: none;

        &::-ms-expand {
            display: none; // to hide dropdown arrow on IE11
        }
    }
}

label {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    color: $color-black;
    text-transform: uppercase;
}

textarea {
    padding: 20px;
    border: 1px solid $color-gray-44;
}

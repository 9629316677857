main {
    min-height: 100px;
}

.header--over-banner + main {
    position: relative;
    top: -$header-height;

    @include small-breakpoint {
        top: -$header-height-small;
    }

    & + .footer {
			margin-top: -$header-height;;

			@include small-breakpoint {
			    margin-top: -$header-height-small / 2;
			}
    }
}
@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-Regular.woff");
  src: url("../fonts/CDMSmith-Regular.woff") format("woff"),
       url('../fonts/CDMSmith-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-Italic.woff");
  src: url("../fonts/CDMSmith-Italic.woff") format("woff"),
       url('../fonts/CDMSmith-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-Bold.woff");
  src: url("../fonts/CDMSmith-Bold.woff") format("woff"),
       url('../fonts/CDMSmith-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-BoldItalic.woff");
  src: url("../fonts/CDMSmith-BoldItalic.woff") format("woff"),
       url('../fonts/CDMSmith-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith Display";
  src: url("../fonts/CDMSmith-Display.woff");
  src: url("../fonts/CDMSmith-Display.woff") format("woff"),
       url('../fonts/CDMSmith-Display.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith Display";
  src: url("../fonts/CDMSmith-DisplayItalic.woff");
  src: url("../fonts/CDMSmith-DisplayItalic.woff") format("woff"),
       url('../fonts/CDMSmith-DisplayItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith Light";
  src: url("../fonts/CDMSmith-Light.woff");
  src: url("../fonts/CDMSmith-Light.woff") format("woff"),
       url('../fonts/CDMSmith-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith Light";
  src: url("../fonts/CDMSmith-LightItalic.woff");
  src: url("../fonts/CDMSmith-LightItalic.woff") format("woff"),
       url('../fonts/CDMSmith-LightItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

/** Brand Colors  -- do we need these? **/
$color-brand-dark-blue:     #003087;
$color-brand-light-blue-font-rd:    #0974BB;
$color-brand-green:         #78c143;
$color-brand-maroon:        #993366;
$color-brand-orange:        #cc9900;
$color-brand-teal:          #009999;

/* Other color variables -- do we need these? */
$color-link: #000;
$color-link-underline: #000;
$color-text: #000;
$color-border: #CCC;
$color-text-subtle: #707070;
$section-title-line: #a5a5a5;

/* Font Families */
$font-sans: "HelveticaNeue", "Helvetica", "Arial", sans-serif;
$font-serif: "Georgia", Times, "Times New Roman", serif;
$font-cdmsmith: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
$font-cdmsmith-sans: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
$font-cdmsmith-display: "CDM Smith Display", serif;
$font-cdmsmith-light: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial",serif;
$font-cdmsmith-light-sans: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
$font-statistics: $font-cdmsmith-light;

$font-secondary-sans: "Arial", sans-serif;
$font-secondary-serif: "Times New Roman", serif;

/* Symbols */
$open-quotation-mark: "\201C";
$close-quotation-mark: "\201D";
$long-dash: "\2013";
$non-break-space: "\00A0";
$carat-right: "\203a";

/* Link base paths */
$domain : "www.cdmsmith.com";

/* Print max width */
$print-max-width: 1250px;

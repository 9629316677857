/*------------------------------------*\
    #PLACEHOLDER SELECTORS

    note: see typography.scss for 
    font size placeholders
\*------------------------------------*/
@import "_breakpoints.scss";
@import "_layout.scss";


/* Clearfix */
%cf {
    *zoom: 1;

    &::before,
    &::after {
        display: table;
        content: " ";
    }

    &::after {
        clear: both;
    }
}

/* Centered and contained content with max-width*/
%content-contained {
    display: block;
    width: 100%;
    max-width: $max-content-width;
    margin: 0 auto;

    @include small-breakpoint {
        max-width: 100%;
    }
}

/* Limit length of standard line of text for readability */
%limit-line-width {
    max-width: 765px;
}

/* Horizontally and vertically center using flexbox */
%flex-center {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

/* Remove link styling on a link */
%no-link-style {
    font-weight: normal;
    border-bottom: 0;

    &:hover {
        border-bottom: 0;
    }
}

/* Remove link styling, except for on hover */
%link-on-hover-only {
    border-bottom: 0;

    &:hover {
        border-bottom: 2px solid $color-link-underline;
    }
}

/* Remove base list styling */
%no-list-style {
    list-style: none;

    li {
        display: inline-block;
    }
}

/* Remove default button styling */
%no-button-style {
    padding: 0;
    background-color: transparent;
    border: 0;
}

$opportunities-small-bp: 500px;

.opportunities {
    position: relative;
}

/* Using background image */
.opportunities__image-container {
    @include aspect-ratio(4,3);

    max-width: 80%;

    @include medium-breakpoint {
        max-width: 100%;
    }

    @include small-breakpoint {
        @include aspect-ratio(3,4);
        width: 100%;
        margin-right: 0;
    }
}

.opportunities__image-picture {
    inset: 0;
    position: absolute;

    img {
        block-size: 100%;
        inline-size: 100%;
        object-fit: cover;
    }
}

.opportunities__rte-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: $max-content-width;
    padding: 40px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include medium-breakpoint {
        display: block;
        margin-top: -150px;
        padding-bottom: 0;
        padding-top: 0;
        position: relative;
    }


    @include small-breakpoint {
    }

    @media(max-width: $opportunities-small-bp) {
        display: block;
        padding: 0;
    }
}

.opportunities__rte {
    display: block;
    padding: 70px;
    background: $color-transparent-blue;
    max-width: 60%;
    max-height: 100%;
    color: $color-white;

    * {
        color: $color-white;
    }

    @include medium-breakpoint {
        padding: 70px;
        max-width: 100%;
    }

    @include small-breakpoint {
        height: auto;
        max-height: none;
        max-width: 100%;
        padding: 20px;
        box-sizing: content-box;
    }
}
/*  NOTE: To make a CSS class appear in the RTE dropdiwn list,
    the style needs to be added to /web/Website/cdmSmith-rte.css
    (hoping to change this in the future)
*/

.rte {
    @extend %content-contained;

    img {
        height: auto;
        max-width: 100%;
    }

    ul, ol {
        padding-left: 20px;
        margin-bottom: 16px;
    }

    .section-title-style-snippet {
        font-family: $font-cdmsmith;
        font-size: $font-size-med;
        font-style: italic;
        font-weight: normal;
        line-height: $font-size-med-line;
        color: $color-text;
    }

    .image-float-right {
        float: right;
        height: auto;
        max-width: 33%;
        margin: 5px 0 10px 10px;

        @include small-breakpoint {
            float: none;
            margin: 10px 0;
            max-width: 100%;
        }
    }

    .image-float-left {
        float: left;
        height: auto;
        max-width: 33%;
        margin: 5px 10px 10px 0;

        @include small-breakpoint {
            float: none;
            margin: 10px 0;
            max-width: 100%;
        }
    }

    .image-caption-float-right {
        float: right;
        margin: 5px 0 10px 10px;
        width: 33%;

        @include small-breakpoint {
            float: none;
            margin: 10px 0;
            width: 100%;
        }
    }

    .image-caption-float-left {
        float: left;
        margin: 5px 10px 10px 0;
        width: 33%;

        @include small-breakpoint {
            float: none;
            margin: 10px 0;
            width: 100%;
        }
    }

    .image-caption-float-left span,
    .image-caption-float-right span {
        font-size: $font-size-small;
    }

    .image-caption-float-left img,
    .image-caption-float-right img {
        height: auto;
        margin-bottom: 5px;
        width: 100%;
    }

	h2
	{
		display: block;
		font-size: $font-size-large;
		line-height: $font-size-large-line;
		margin-left: 0;
		margin-right: 0;
		font-weight: bold;
	}
}

/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/
$max-page-width: 1600px;
$max-content-width: 1200px;

$header-height: 100px;
$header-height-small: 75px;

$section-margin-bottom: 30px;


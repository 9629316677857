.cta-without-image {
    @extend %content-contained;
    padding: 40px;

    @include medium-breakpoint {
        padding: 20px 40px;
    }

    @include small-breakpoint {
        display: flex;
        flex-direction: column;
        padding: 20px;
    }
}



.split-container__inner--30 .cta-without-image {
    flex-direction: column;

    .cta-without-image__header {
        font-size: $font-size-med;
        line-height: $font-size-med-line;
    }

    .cta-without-image__flex-block {
        flex-direction: column;
    }

    .cta-without-image__button {
        align-self: flex-start;
        flex: auto;
    }
}

.split-container__inner--50 .cta-without-image {
    flex-direction: column;

    .cta-without-image__flex-block {
      flex-direction: column;
    }

    .cta-without-image__button {
      align-self: flex-start;
    }

    .cta-without-image__header {
      font-size: $font-size-med-2;
      line-height: $font-size-med-2-line;
    }

    .cta-without-image__summary {
      font-size: $body-cdmsmith-font-size;
      line-height: $body-cdmsmith-line;
    }

    .cta-without-image__name, .cta-without-image__title {
      font-size: $body-cdmsmith-font-size;
      line-height: $body-cdmsmith-line;
    }
}

.rail-container__content .cta-without-image-section .cta-without-image {
    padding: 20px; /* overly specific to override without impt */
}

@media only screen and (max-width:799px) {
    .split-container__inner--30 .cta-without-image .cta-without-image__header{
        font-size: $font-size-med;
        line-height: $font-size-med-line;
    }

    .split-container__inner--30 .cta-with-image .cta-with-image__header {
        font-size: $font-size-med;
        line-height: $font-size-med-line;
    }
}

.cta-without-image__header {
  font-size: $font-size-med;
  line-height: $font-size-med-line;
}

.cta-without-image__type {
    @extend .heading--small;
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

.cta-without-image__thumbnail {
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 5px;
  img {
    margin-right: 20px;
    max-width: 100%;
}
    @include small-breakpoint {
        display: none;
    }
}

.cta-without-image__flex-block {
    display: flex;
    justify-content: space-between;

    @include medium-breakpoint {
        flex-direction: column;
    }
}

.cta-without-image__image-wrap-block {
    display: flex;
    flex-grow: 1;
    margin-right: 15px;

    .cta-without-image__thumbnail {
        float: left;
        align-self: flex-start;
    }

    .split-container__inner--50 & {
        display: block;

        @include medium-breakpoint {
            display: flex;
        }
    }

    .split-container__inner--30 & {
        margin-right: 0;
    }
}

.cta-without-image__text-block {
    float: left;
    max-width: 70%;

    .split-container__inner--50 & {
        float: none;
        max-width: none;
    }

    .split-container__inner--30 & {
        float: none;
        max-width: none;
    }

    @include medium-breakpoint {
        max-width: none;
    }
}

.cta-without-image__header {
    margin-bottom: 10px;
    font-size: $font-size-med-3;
    line-height: $font-size-med-3-line;
    font-weight: 700;

    @include medium-breakpoint {
        font-size: $font-size-med-3;
        line-height: $font-size-med-3-line;
    }
}

.cta-without-image__summary {
    @extend %limit-line-width;
    margin-bottom: 15px;

    @include small-breakpoint {
        font-size: $font-size-small-3;
        line-height: $font-size-small-3-line;
    }

    .split-container__inner--50 & {
        float: left;
    }
}

.cta-without-image__button {
    align-self: flex-end;

    @include medium-breakpoint {
        display: inline-block;
        align-self: flex-start;
        width: auto;
    }
}



/* Theme variations */
.cta-without-image.theme-grey {
    background-color: $alt-background-color;

    .cta-without-image__type {
        color: $color-gray-35;
    }

    .cta-without-image__button {
        color: $color-white;
        background-color: $color-brand-light-blue-acc;
        &:hover {
          color: $color-brand-light-blue-acc;
          background-color: $color-white;
          outline : 2px solid $color-brand-light-blue-acc;
        }
    }
}

.cta-without-image.theme-blue {
    background-color: $color-brand-dark-blue;

    .cta-without-image__type {
        color: $color-white;
    }

    .cta-without-image__header,
    .cta-without-image__summary {
        color: $color-white;
    }

    .cta-without-image__button {
        @extend .btn--white;
        color: $color-brand-dark-blue !important;
        background-color: $color-white !important;
        &:hover {
          color: $color-white !important;
          background-color: $color-brand-dark-blue !important;
          outline: 2px solid $color-white !important;
        }
    }

    .cta-without-image__button .btn .cookie-policy-toggle{
      color: $color-brand-dark-blue;
      background-color: $color-white;
      &:hover {
        color: $color-white;
        background-color: $color-brand-dark-blue;
        outline: 2px solid $color-white;
      }
    }
}

.cta-without-image.theme-light-blue {
    background-color: $color-brand-light-blue;

    .cta-without-image__type {
        color: $color-white;
    }

    .cta-without-image__header,
    .cta-without-image__summary {
        color: $color-white;
    }

    .cta-without-image__button {
        @extend .btn--white;
        color: $color-brand-light-blue-acc;
        background-color: $color-white;
        &:hover {
          color: $color-white;
          background-color: $color-brand-light-blue-acc;
          outline : 2px solid $color-white;
        }
      }
}

.cta-without-image.theme-white {
    display: flex;
    flex-direction: column;
    padding: 15px 20px 20px;
    background-color: $color-white;
    border-top: 4px solid $color-gray-15;
    border-bottom: 2px solid $color-gray-15;

    .cta-without-image__type {
        color: $color-gray-35;
    }

    .cta-without-image__flex-block {
        display: flex;
    }

    .cta-without-image__button {
        @extend .btn--white;
        align-self: flex-end;
        flex-grow: 0;
        position: relative;
        font-size: 13px;
        height: 44px;
        color: $color-white !important;
        background-color: $color-brand-light-blue-acc !important;

        @include medium-breakpoint {
            width: auto;
            margin-bottom: 0;
            align-self: flex-end;
        }

        &:hover {
          color: $color-brand-light-blue-acc !important;
          background-color: $color-white !important;
          outline : 2px solid $color-brand-light-blue-acc !important;
        }
    }
}

.cta-without-image__print-email {
  display: none;
}

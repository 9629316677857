/*------------------------------------*\
    #BREAKPOINTS
    note:   responsive styles/breakpts
            are written desktop-first
\*------------------------------------*/

/** IMPORTANT:
    breakpoints are hardcoded in the
    cshtml files for all banner components.
    any changes to these variables must be
    replicated there
**/
$large-width: 1200px;
$medium-width: 800px;

/*------------------------------------*\
    #MEDIA QUERY MIXINS
    usage:  @include small-breakpoint {
                font-size: 18px;
            }
\*------------------------------------*/

@mixin large-breakpoint {
    @media (min-width: #{$large-width}) {
        @content;
    }
}

@mixin medium-breakpoint {
    @media (max-width: #{$large-width - 1px}) {
        @content;
    }
}

@mixin small-breakpoint {
    @media (max-width: #{$medium-width - 1px}) {
        @content;
    }
}

/*------------------------------------*\
    #RESPONSIVE TOGGLE MIXINS
    usage:  @include large-only(flex);
\*------------------------------------*/

@mixin large-only($visible-block-style: block) {
    display: $visible-block-style;

    @include medium-breakpoint {
        display: none;
    }
}

@mixin medium-only($visible-block-style: block) {
    display: none;

    @include medium-breakpoint {
        display: $visible-block-style;
    }

    @include small-breakpoint {
        display: none;
    }
}

@mixin medium-and-up($visible-block-style: block) {
    display: $visible-block-style;

    @include small-breakpoint {
        display: none;
    }
}

@mixin small-only($visible-block-style: block) {
    display: none;

    @include small-breakpoint {
        display: $visible-block-style;
    }
}


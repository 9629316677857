/*------------------------------------*\
    #RESET
    Styles taken from reset and
    normalize, with some modifications
\*------------------------------------*/

/** BASE (BUNCH OF ELEMENTS) **/
    // Use border box on all elements
    * {
        box-sizing: border-box;
    }
    // Display block for a bunch of elements
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    nav,
    section,
    summary {
        display: block;
    }
    // Zero out margin and padding on most elements as base style
    html, body, div, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, ol, ul, li, form, legend, label, table, header, footer, nav, section, figure {
        padding: 0;
        margin: 0;
        vertical-align: baseline;
        border: 0;
    }
    // Address `[hidden]` styling not present in IE 8/9.
    // Hide the `template` element in IE, Safari, and Firefox < 22.
    [hidden], template {
        display: none;
    }


/** HTML & BODY **/
    html {
        // Prevents iOS text size adjust after orientation change, without disabling user zoom
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        // Addresses font-family inconsistency between 'textarea' and other form elements.
        font-family: sans-serif;
    }
    // Addresses margins handled incorrectly in IE6/7
    body {
        margin: 0;
    }


/** IMAGES **/
    // Corrects overflow displayed oddly in IE9
    svg:not(:root) {
        overflow: hidden;
    }
    // Removes border when inside 'a' element in IE6/7/8/9, FF3
    img {
        border: 0;
    }
    // Addresses margin not present in IE6/7/8/9, S5, O11
    figure {
        margin: 0;
    }


/** AUDIO & VIDEO **/
    // Corrects inline-block display not defined in IE6/7/8/9 & FF3
    audio,
    canvas,
    video {
        display: inline-block;
    }
    // Prevents modern browsers from displaying 'audio' without controls & removes excess height is iOS5
    audio:not([controls]) {
        display: none;
        height: 0;
    }


/** SPECIAL TEXT ELEMENTS **/
    // Addresses style set to 'bolder' in FF3+, S4/5, Chrome
    b,
    strong {
        font-weight: bold;
    }
    // Improves readability of pre-formatted text in all browsers
    pre {
        white-space: pre;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    // Set consistent quote types.
    q {
        quotes: "\201C" "\201D" "\2018" "\2019";
    }
    // Address inconsistent and variable font size in all browsers.
    small {
        font-size: 80%;
    }
    // Prevents sub and sup affecting line-height in all browsers
    // gist.github.com/413930
    sub,
    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }
    sup {
        top: -0.5em;
    }
    sub {
        bottom: -0.25em;
    }
    // Addresses styling not present in IE6/7/8/9
    mark {
        background: #ff0;
        color: #000;
    }
    // Addresses styling not present in S5, Chrome
    dfn {
        font-style: italic;
    }


/** BUTTONS **/
    // Remove some of default button styling & normalize
    button {
        cursor: pointer;
        font-family: sans-serif;
        line-height: normal;
        font-size: 100%;
        margin: 0;
        vertical-align: baseline;
        background: transparent;
        border: 0;
    }
    button[disabled] {
        cursor: default;
    }
    // Removes inner padding and border in FF3+
    // www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
    button {
        &::-moz-focus-inner {
            border: 0;
            padding: 0;
        }
    }


/** FORM ELEMENTS **/
    input,
    select,
    textarea {
        font-family: sans-serif;
        font-size: 100%;
        margin: 0;
        vertical-align: baseline;
    }
    input {
        line-height: normal;
    }
    input[disabled] {
        cursor: default;
    }
    // 1. Addresses appearance set to searchfield in S5, Chrome
    // 2. Addresses box-sizing set to border-box in S5, Chrome (include -moz to future-proof)
    // input[type="search"] {
    //     -webkit-appearance: textfield; // 1
    //     -moz-box-sizing: content-box;
    //     -webkit-box-sizing: content-box; // 2
    //     box-sizing: content-box;
    // }
    // Remove inner padding and search cancel button in Safari 5 and Chrome
    // on OS X.
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
    }
    // Removes inner padding and border in FF3+
    // www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
    input {
        &::-moz-focus-inner {
            border: 0;
            padding: 0;
        }
    }
    html input[type="button"],
    input[type="reset"],
    input[type="submit"] {
        cursor: pointer;
    }
    // 1. Address box sizing set to `content-box` in IE 8/9/10.
    // 2. Remove excess padding in IE 8/9/10.
    input[type="checkbox"],
    input[type="radio"] {
        box-sizing: border-box; // 1
        padding: 0; // 2
    }
    fieldset {
        min-width: 0;
        padding: 0.01em 0 0;
        margin: 0;
        border: 0;
    }
    body:not(:-moz-handler-blocked) fieldset {
        display: table-cell;
    }
    // Remove wacky fieldset browser styling
    legend {
        display: table;
        padding: 0;
        border: 0;
        white-space: normal;
    }
    // 1. Removes default vertical scrollbar in IE6/7/8/9
    // 2. Improves readability and alignment in all browsers
    textarea {
        overflow: auto; // 1
        vertical-align: top; // 2
    }


/** TABLES **/
    // Remove most spacing between table cells
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
.expert-quote-promo {
    @extend %content-contained;
    font-size: 16px !important;
    position: relative;
    color: $color-white;
    overflow: hidden;
}

.expert-quote-promo__color-block {
    position: relative;
    display: inline-block;
    width: 55%;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        z-index: 0;
    }
}

.expert-quote-promo__color-block div {
    position: relative;
    z-index: 1;
}

.expert-quote-promo__quote {
    font-size: $body-cdmsmith-font-size;
    line-height: $body-cdmsmith-line;
    margin-bottom: 20px;
    color: $color-white;

    &:last-child {
        margin-bottom: 0;
    }
}

.expert-quote-promo__title {
    font-size: $font-size-small;
    font-weight: normal !important;
    color: $color-white;
    margin-top: 5px;
}

.expert-quote-promo__name {
    font-size: $font-size-small;
    line-height: $font-size-small-line;
    font-weight: 700;
    color: $color-white;
    margin-bottom: 5px;
}

a.expert-quote-promo__name {
    color: $color-white;
    font-size: $font-size-small;
    line-height: $font-size-small-line;
    border-bottom: 1px solid  $color-white !important;
    text-decoration: none !important;
    &:hover {
      color: $color-white;
      border-bottom: 3px solid  $color-white !important;
    }
}

.expert-quote-promo__flex-wrap {
    display: flex;
    justify-content: space-between;
}

/*  Note : Had to use mixins for the below styles
    (instead of regular classes or placeholders)
    b/c you can't extend from within the media query
    mixin.
*/

/** 3 Style Versions: Large, Medium Small **/
/** (they don't just correspond to breakpoints) **/


/*  Only for components in main content container,
    at large breakpoint
*/
@mixin expert-quote-promo--size-large {

    .expert-quote-promo__color-block {
        padding: 60px 30px 60px 60px;
    }

    .expert-quote-promo__quote {
        font-size: 20px;
        line-height: 28px;
    }

    .fa-quote-left {
        position: absolute;
        top: -40px;
        left: -20px;
        display: block;
        color: $color-transparent-white-30;
    }

    .expert-quote-promo__name,
    .expert-quote-promo__title {
        font-size: $font-size-small;
        line-height: $font-size-small-line;
    }

    .expert-quote-promo__image {
        block-size: auto;
        display: block;
        object-fit: cover;
        position: absolute;
        right: 0;
        min-width: 50%;
    }
}

/*  For all components at medium breakpoint,
    and also in 50% container at large breakpoints
*/
@mixin expert-quote-promo--size-medium {
    .expert-quote-promo__color-block {
        top: -190px;
        margin-bottom: -190px;
        width: 100%;
        max-width: 100%;
        padding: 50px;
    }

    .expert-quote-promo__quote {
        font-size: $body-cdmsmith-font-size;
        line-height: $body-cdmsmith-line;
    }

    .fa-quote-left {
        display: block;
    }

    .expert-quote-promo__name,
    .expert-quote-promo__title {
        font-size: $body-cdmsmith-font-size;
        line-height: $body-cdmsmith-line;
    }

    .expert-quote-promo__image {
        block-size: auto;
        display: block;
        object-fit: cover;
        position: relative;
        width: 100%;
    }
}

/*  For all components at small breakpoint,
    and also in 30% container at large breakpoints
*/
@mixin expert-quote-promo--size-small {
    .expert-quote-promo__color-block {
        top: -100px;
        margin-bottom: -100px;
        width: 100%;
        max-width: 100%;
        padding: 20px;
    }

    .expert-quote-promo__quote {
        font-size: $body-cdmsmith-font-size;
        line-height: $body-cdmsmith-line;
    }

    .fa-quote-left {
        font-size: 40px;
        top: -15px;
        left: -13px;
    }

    .expert-quote-promo__name,
    .expert-quote-promo__title {
        font-size: $font-size-small;
        line-height: $font-size-small-line;
    }

    .expert-quote-promo__image {
        block-size: auto;
        display: block;
        object-fit: cover;
        position: relative;
        width: 100%;
    }
}


/*  Applying the correct styles to the correct
    breakpoints and containers
*/
.expert-quote-promo {
    @include expert-quote-promo--size-large();

    @include medium-breakpoint {
        @include expert-quote-promo--size-medium();
    }

    @include small-breakpoint {
        @include expert-quote-promo--size-small();
    }
}

.split-container__inner--50 .expert-quote-promo {
    @include expert-quote-promo--size-medium();

    @include small-breakpoint {
        @include expert-quote-promo--size-small();
    }
}

.split-container__inner--30 .expert-quote-promo {
    @include expert-quote-promo--size-small();

    @include medium-breakpoint {
        @include expert-quote-promo--size-medium();
    }

    @include small-breakpoint {
        @include expert-quote-promo--size-small();
    }
}

.split-container__inner--30 .expert-quote-promo .expert-quote-promo__quote {
    font-size: $body-cdmsmith-font-size;
    line-height: $body-cdmsmith-line;
}

.split-container__inner--30 .expert-quote-promo .expert-quote-promo__name {
    font-size: $font-size-small;
    line-height: $font-size-small-line;
}

.split-container__inner--30 .expert-quote-promo .expert-quote-promo__title {
  font-size: $font-size-small;
  line-height: $font-size-small-line;
}

/* Color themes */
.expert-quote-promo.theme-light-blue {
    .expert-quote-promo__color-block::after {
        background: $color-transparent-light-blue;
    }
}

.expert-quote-promo.theme-blue {
    .expert-quote-promo__color-block::after {
        background: $color-transparent-blue;
    }
}

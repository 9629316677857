/*------------------------------------*\
    #MIXINS
    note:   see breakpoints.scss
            for media query mixins
\*------------------------------------*/
@import "breakpoints";

@import "mixins/aspect-ratio";
@import "mixins/colored-stripe";
@import "mixins/dot-before-heading";
@import "mixins/line-after-heading";
@import "mixins/line-beneath-heading";
@import "mixins/linear-gradient-background";
@import "mixins/object-fit";
@import "mixins/text-shadow";
@import "mixins/visually-hidden";
@import "mixins/retina-query";

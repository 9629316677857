$photo-caption-description-bg: rgba(35,31,32,0.8);
$photo-caption-description-bg-small: rgb(35,31,32);
$photo-caption-small-bp: 520px;

.photo-caption {

    /* slick slider styles */
    &.slick-dotted {
        margin-bottom: 70px;
    }

    .slick-disabled {
        visibility: hidden;
    }

    .slick-dots {
        bottom: -30px;

        .slick-active {
            background-color: $color-brand-green;
        }

        li {
            height: auto;
            width: auto;
            background-color: $color-gray-15;
        }

        button {
            height: 10px;
            width: 18px;
            padding: 0;

            &:before {
                display: none;
            }
        }
    }

    @media (max-width: $photo-caption-small-bp) {
        .slick-arrow {
            width: 30px;
        }
    }
}

.photo-caption__description {
    background-color: $photo-caption-description-bg-small;
    color: $color-white;
    padding: 20px 40px;
    position: relative;
    width: 100%;

    p {
        max-width: none;
    }

    @include large-breakpoint {
        background-color: $photo-caption-description-bg;
        padding: 10px 40px;
        position: absolute;
            bottom: 0;
    }
}

.photo-caption__slide {
    position: relative;

    .experience-editor & {
        margin-bottom: 20px;
        min-height: 150px;
    }
}

.photo-caption__picture {
    block-size: 100%;
    inline-size: 100%;
    inset: 0;
    position: absolute;

    img {
        block-size: 100%;
        inline-size: 100%;
        object-fit: cover;
    }
}

.photo-caption__summary {
    color: $color-white;
    font-size: $font-size-small;
    line-height: 20px;
    margin-bottom: 0;

}

.photo-caption__copyright {
    font-size: 12px;
    font-style: italic;
    line-height: 20px;
}


.photo-caption__image {
    @include aspect-ratio(16, 9);
    background-position: center;
    background-size: cover;
}

.breadcrumb-wrapper {
    @extend %content-contained;

    @include small-breakpoint {
        padding: 15px 20px;
    }

    main > & {
        margin-bottom: 0;
    }
}

.breadcrumb {
    max-width: $max-content-width;
    margin: 0 auto;
    list-style: none;

    @include small-breakpoint {
        max-width: 100%;
    }
}

.breadcrumb li {
    @extend .heading--small-subtle;
    display: inline-block;
    font-size: $font-size-small;
}

.breadcrumb__divider {
    @extend .heading--small-subtle;
    display: inline-block;
    font-size: $font-size-small;
    margin: 0 7px 0 10px;
}

.breadcrumb li.current {
    color: $color-brand-light-blue-font-rd !important;
}

.breadcrumb a {
    @extend .heading--small-subtle;
    @extend %link-on-hover-only;
    border-bottom: 2px solid $color-white;
    display: inline-block;
    font-size: $font-size-small;
}

.image {
    @extend %content-contained;
    margin: 0 auto;
    text-align: center;
}

.image__wrapper {
    position: relative;
    display: inline-block;
    max-width: 100%;

    img {
        block-size: auto;
        max-width: 100%;
        max-height: 600px;
        object-fit: cover;

        @include small-breakpoint {
            max-height: 80vh;
        }
    }
}

.image__copyright {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 100%;
    background-color: $color-dim-60;
    color: $color-white;
    padding: 10px;
    font-size: $font-size-small;
    line-height: $font-size-small-line;

    @include small-breakpoint {
        padding: 5px;
        font-size: $font-size-small;
        line-height: $font-size-small-line;
    }
}

.object-fit--cover {
  font-family: 'object-fit: cover';
  //https://github.com/bfred-it/object-fit-images/ IE fix
}

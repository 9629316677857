$button-height: 40px;

.focus-area-section {
    padding: 40px;

    @include small-breakpoint {
        padding: 20px;
    }
}

.focus-area-section__content {
    max-width: $max-content-width;
    margin: 0 auto;
    position: relative;
    width: 100%;
}

.focus-area-section__title {
  color: $color-white; // default
  font-family: $font-cdmsmith;
  font-size: 22px;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 18px;
}

// focus items (located here because they need the section color context)

.focus-items {
  display: flex;
  flex-wrap: wrap;
}



.focus-item-teaser {
  border-top-color: $color-brand-light-blue; // just a default
  border-top-style: solid;
  border-top-width: 4px;
  font-size: 16px;
  margin-bottom: 20px;
  flex-grow: 0;
  margin: 0 40px;
  padding: 37px 0;
  position: relative;
  width: calc(50% - 80px);

  &:nth-child(odd) {
    margin-left: 0;
  }

  &:nth-child(even) {
    margin-right: 0
  }

  @include small-breakpoint {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 20px 0;
  }
}

.focus-item-teaser__title {
  color: $color-white; // default
  font-size: $font-size-med-3;
  line-height: $font-size-med-3-line;
  letter-spacing: 0px;
  margin-bottom: 5px;
}

.focus-item-teaser__summary {
  color: $color-white; // default
  font-family: $font-cdmsmith;
  font-size: $font-size-small-3;
  line-height: $font-size-small-3-line;
  margin-bottom: 10px;
}

@media only screen and (max-width:799px) {
  .focus-item-teaser__title{
      font-size: $font-size-med;
      line-height: $font-size-med-line;
  }

  .focus-item-teaser__summary{
      font-size: $font-size-small-2;
      line-height: $font-size-small-2-line;
  }
}

.focus-item-teaser__button-space-saver {
    width: 100%;
    height: $button-height;
}

.focus-item-teaser__button-wrapper {
    display: block;
    width: 100%;
    text-align: right;
}

.focus-item-teaser__button {
  border-bottom: none;
  color: $color-white; // default
  font-family: $font-cdmsmith;
  font-size: $font-size-small;
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  vertical-align: baseline;

  &:hover {
    border-bottom: none;
  }

  &:after {
    background-color: $color-brand-light-blue;
    color: $color-white;
    content: $carat-right;
    display: inline-block;
    font-size: $button-height;
    font-weight: 100;
    height: $button-height;
    line-height: 35px;
    margin-left: 15px;
    position: relative;
    bottom: -1px;
    text-align: center;
    width: $button-height;
    vertical-align: middle;
  }
}

// theme colors
.focus-area-section--theme-blue {
  background-color: $color-brand-dark-blue;
  .focus-area-section__title {
    color: $color-white;
    margin-top: 20px;
  }
  .focus-item-teaser {
    border-top-color: $color-brand-light-blue;
  }
  .focus-item-teaser__title {
    color: $color-white;
  }
  .focus-item-teaser__summary {
    color: $color-white;
  }
  .focus-item-teaser__button-space-saver {
    display: none;
  }
  .focus-item-teaser__button-wrapper {
    position: static;
  }
  .focus-item-teaser__button {
    color: $color-white;
  }
}

.focus-area-section--theme-white {
  background-color: $color-white;
  .focus-area-section__title {
    color: $color-black;
  }
  .focus-item-teaser {
    border-top-color: $color-brand-light-blue;
    border-bottom: 2px solid $color-gray-15;
    padding-bottom: 0;
    margin-bottom: 40px;
  }
  .focus-item-teaser__title {
    color: $color-black;
  }
  .focus-item-teaser__summary {
    color: $color-gray-73;
  }
  .focus-item-teaser__button-space-saver {
    display: block;
  }
  .focus-item-teaser__button-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .focus-item-teaser__button {
    color: $color-brand-light-blue;
    font-weight: bold;
  }
}

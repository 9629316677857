$search-results__sort-arrow-size: 24;
$search-results-border-color: $color-gray-15;
$search-results-sorter-border: $color-brand-green;



.search-results__sort-bar {
	margin-bottom: 20px;
}

.search-results__sort-options {
	display: inline-block;
}

.search-results__sorter {
	border-right: 1px solid $color-gray-87;
	display: inline-block;
	list-style: none;
	margin-right: 15px;
	padding-right: 15px;

	&:last-child {
		border-right: none;
		margin-right: 0;
		padding-right: 0;
	}
}

.search-results__load-more {
	text-align: center;

	.btn{
			color: $color-white;
			background-color: $color-brand-light-blue-acc;
			border-radius: 0px;

			@media(hover: hover){
				&:hover {
					color: $color-brand-light-blue-acc;
					background-color: $color-white;
					outline: 2px solid $color-brand-light-blue-acc;
				}
			}
			&:focus{
				outline: 2px solid $color-brand-light-blue-acc;
			}
	}
}

.search-results__status-sort {
	border-bottom: 4px solid $search-results-border-color;
	margin-bottom: 50px;
}

.search-results__sort-label {
	display: inline-block;
	text-transform: uppercase;
	line-height: 1.3;

	&:hover {
		cursor: pointer;
	}

	.is-active & {
		border-bottom: 1px solid $search-results-sorter-border;
		font-weight: 700;
	}
}

.search-results__sort-by {
	color: $color-gray-40;
	font-weight: 700;
	font-size: $font-size-small;
	display: inline-block;
	margin-right: 10px;
	text-transform: uppercase;
	vertical-align: middle;
}

.search-results__sort-dir {
	display: inline-block;
	position: relative;
	top: 6px;
	vertical-align: middle;

	button,
	svg {
		width: 24px;
		height: 24px;
	}
	button {
		color: $search-results-border-color;
		display: block;
		text-align: center;
		background: transparent;
		border: 0;
		padding: 0;
		margin-top: -16px;

		&.is-selected {
			color: $color-brand-green;
		}
	}

	svg {
		fill: currentColor;
	}
}

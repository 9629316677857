@mixin line-beneath-heading ($line-color: $color-brand-dark-blue, $margin: 20px) {
    &::after {
        display: block;
        width: 70px;
        height: 2px;
        margin: $margin auto;
        background-color: $line-color;
        content: "";
    }
}

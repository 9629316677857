@mixin aspect-ratio($width, $height) {
    position: relative;

    &::before {
        display: block;
        width: 100%;
        padding-top: ($height / $width) * 100%;
        content: "";
    }

    > .content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

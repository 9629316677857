/*------------------------------------*\
    #COLORS
\*------------------------------------*/

/** Brand Colors **/
$color-brand-dark-blue:         #003087;
$color-brand-light-blue:        #0475BC;
$color-brand-light-blue-font-rd:#0475BC;
$color-brand-light-blue-acc:    #0475BC;
$color-brand-green:             #78c143;
$color-brand-light-green:       #0475BC;
$color-brand-maroon:            #993366;
$color-brand-orange:            #cc9900;
$color-brand-teal:              #009999;

$color-brand-dark-green:    darken($color-brand-green, 15%);
$color-brand-dark-maroon:   darken($color-brand-maroon, 15%);
$color-brand-dark-orange:   darken($color-brand-orange, 15%);
$color-brand-dark-teal:     darken($color-brand-teal, 15%);
$color-brand-darker-light-blue:     darken($color-brand-light-blue, 15%);


$color-opacity-white: rgba(250,250,250, 0.6); //requested 50% would not meet accessibility guidelines so it's now 80%


/** Transparent Brand Colors **/
$color-transparent-blue-95:        rgba(0, 48, 135, 0.95);
$color-transparent-blue:        rgba(0, 48, 135, 0.8);
$color-transparent-light-blue:  rgba(0, 153, 238, 0.8);
$color-transparent-green:       rgba(120, 193, 67, 0.8);
$color-transparent-maroon:      rgba(153, 51, 102, 0.8);
$color-transparent-orange:      rgba(204, 153, 0, 0.8);
$color-transparent-teal:        rgba(0, 153, 153, 0.8);

$color-transparent-dark-green:  rgba(84, 138, 46, 0.8);
$color-transparent-dark-maroon: rgba(96, 32, 64, 0.8);
$color-transparent-dark-orange: rgba(128, 96, 0, 0.8);
$color-transparent-dark-teal:   rgba(0, 77, 77, 0.8);
$color-transparent-darker-light-blue:   rgba(0, 104, 162, 0.8);
$color-transparent-darker-dark-blue:   rgba(0, 10, 97, 0.8);


/** Neutral Colors **/
    /* Note: Neutral color variables use a numbered system to define variables for better maintainability.
    "$color-gray-10" means "10% gray", which is a gray closer to white than to black. For more info read https://medium.com/eightshapes-llc/color-in-design-systems-a1c80f65fa3#.brevrq67p */
$color-white:   #fff;
$color-gray-02: #f7f9f9;
$color-gray-15: #d9d9d9;
$color-gray-35: #a5a5a5;
$color-gray-40: #999999;
$color-gray-44: #909090;
$color-gray-50: #707070;
$color-gray-55: #737373;
$color-gray-65: #595959;
$color-gray-73: #444;
$color-gray-87: #222;
$color-black:   #000;
$color-tundora: #424242;


/** Transparent Neutral Colors **/
$color-transparent:    rgba(255,255,255,0);
$color-transparent-white-10:    rgba(255,255,255,0.1);
$color-transparent-white-30:    rgba(255,255,255,0.3);
$color-transparent-white-50:    rgba(255,255,255,0.5);
$color-transparent-white-85:    rgba(255,255,255,0.85);
$color-transparent-white-90:    rgba(255,255,255,0.9);
$color-dim-15:                  rgba(0,0,0,0.15);
$color-dim-30:                  rgba(0,0,0,0.3);
$color-dim-40:                  rgba(0,0,0,0.4);
$color-dim-50:                  rgba(0,0,0,0.5);
$color-dim-60:                  rgba(0,0,0,0.6);
$color-dim-70:                  rgba(0,0,0,0.7);


/** Color Mapping **/
$alt-background-color: $color-gray-02;
$color-text: $color-gray-87;
$color-text-subtle: $color-gray-50;
$color-link: $color-text;
$color-link-underline: $color-brand-light-blue;

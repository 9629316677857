/* styles shared by both horizontal and vertical displays */
.statistics-wrapper {
    @extend %content-contained;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
}

.statistic {
    padding: 40px 20px;
    text-align: center;
    background-color: $color-white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.statistic__picture {
    block-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 50%;
    position: absolute;
    transform: translate(-50%);

    img {
        block-size: 100%;
    }
}

.statistic__super{
  font-size: $font-size-large;
}

.statistic__counter-number {
    position: relative;
}

.statistic__super,
.statistic__counter-number {
    display: inline;
    font-family: $font-statistics !important;
    color: $color-brand-light-blue-font-rd !important;
    font-weight: 700 !important;
}

.statistic__summary {
    font-family: $font-cdmsmith;
    max-width: 300px;
    margin: 0px auto 0 !important;
    color: $color-gray-87;
    position: relative;
}

.statistics-wrapper {

    .statistics-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: stretch;
        flex-direction: row;
        align-items: stretch;
        margin-left: -2px;

        // vertical stack
        .split-container__inner--30 {
            @include large-breakpoint {
                display: block;
            }
        }
    }
    .statistic {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        margin-bottom: 20px;
        position: relative;
        width: 245px;

        @include large-breakpoint {
            .split-container__inner--30 & {
                flex-basis: 100%;
                padding: 0 20px 20px;
            }
        }

        &:before {
            background-color: $color-gray-15;
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 130px;
            width: 2px;
            z-index: 1;
        }
    }

    .statistic__super {
        font-size: 52px;

        @include small-breakpoint {
            font-size: $font-size-large;
            padding: 20px 0;
        }

        .split-container__inner--30 & {
            @include large-breakpoint {
                font-size: $font-size-large;
            }
        }
    }
    .statistic__counter-number {
        font-size: $font-size-large-3;
        line-height: $font-size-large-3-line;

        @include small-breakpoint {
            font-size: $font-size-large-3-m;
            line-height: $font-size-large-3-m-line;
        }

        .split-container__inner--30 & {
            @include large-breakpoint {
                font-size: $font-size-large-2;
                line-height: $font-size-large-2-line;
            }
        }
    }
}



@media only screen and (max-width:799px) {
    .statistics-wrapper .statistic__counter-number{
        font-size: $font-size-large-3-m;
        line-height: $font-size-large-3-m-line;
    }
}

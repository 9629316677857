a {
  font-weight: 700;
  color: $color-link;
  text-decoration: none;
  border-bottom: 1px solid $color-link-underline;
  &:hover {
    text-decoration: none;
    border-bottom: 2px solid $color-link-underline;
  }
}

.btn {
  display: inline-block;
  min-height: 44px;
  padding: 14px 30px;
  font-family: $font-cdmsmith-sans;
  font-size: $font-size-small;
  font-weight: 600;
  letter-spacing: 3px;
  color: $color-white;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background: $color-brand-light-blue;
  border-bottom: 0;
  transition: background 0.2s ease;
  max-width: 100%;
  word-wrap: normal;
  @include small-breakpoint {
    width: 100%;
    margin-bottom: 20px;
  }
  &:hover {
    background: $color-brand-dark-green;
    border-bottom: 0;
  }
  &:disabled {
    background: $color-gray-35;
    cursor: default;
    &:hover {
      background: $color-gray-35;
      border-bottom: 0;
    }
  }
  .fa-external-link {
    color: $color-white;
  }
}

.btn--blue {
  background: $color-brand-light-blue;
  .fa-external-link {
    color: $color-white;
  }
  &:hover {
    background: $color-brand-dark-blue;
  }
  &:focus {
    outline: $color-brand-darker-light-blue solid 2px;
  }
}

.btn--white {
  color: $color-brand-dark-blue;
  background: $color-white;
  .fa-external-link {
    color: $color-brand-light-blue;
  }
  &:hover {
    color: $color-white;
    background: $color-brand-dark-blue;
    background-color: $color-brand-dark-blue;
    outline : 2px solid $color-white;
  }
}

.token {
  display: inline-block;
  height: 44px;
  padding: 10px 14px;
  background-color: $color-gray-02;
  border-bottom: 0;
}

.token:hover {
  background-color: $color-brand-green;
  border-bottom: 0;
  .token__text {
    color: $color-white;
  }
  .token__clear {
    color: $color-white;
    border-bottom: 0;
  }
}

.token__text {
  padding: 0 10px;
  font-size: $body-cdmsmith-font-size;
  font-weight: bold;
  color: $color-text;
}

.token__clear {
  color: $color-brand-green;
}

.event-promo {
    @extend %content-contained;
    display: flex;
    justify-content: space-between;

    @include medium-breakpoint {
        display: block;
    }
}

.event-promo.has-alt-background {
    background: $alt-background-color;
    padding: 20px;
}

.event-promo__text {
    margin-right: 40px;

    @include medium-breakpoint {
        margin-right: 0;
    }
}

.event-promo__title {
    @extend %limit-line-width;
    @extend .heading--small;
    margin-bottom: 15px;
}

.event-promo__date {
    @extend %limit-line-width;
    margin-bottom: 10px;
    font-size: $font-size-med-2;
    line-height: $font-size-med-2-line;
    font-weight: 700;
    color: $color-brand-light-blue-font-rd;
}

@media only screen and (max-width:799px) {
    .event-promo__date{
        font-size: $font-size-med;
        line-height: $font-size-med;
    }
}

.event-promo__summary {
    margin-bottom: 30px;
}

.event-promo__button {
    align-self: flex-end;
    margin-bottom: 15px;
    white-space: nowrap;
    color: $color-white;
    background-color: $color-brand-light-blue-acc;
    &:hover {
      color: $color-brand-light-blue-acc;
      background-color: $color-white;
      outline: 2px solid $color-brand-light-blue-acc;
    }
}

.split-container .event-promo {
    display: block;

    .event-promo__text {
        margin-right: 0;
    }
}

.split-container__inner--30 .event-promo {
    .event-promo__button {
        width: 100%;

        @include medium-breakpoint {
            width: auto;
        }
    }
}

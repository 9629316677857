$search-background-color: #f4f5f6;

.search {
	.search-box {
		margin-bottom: rem(60);
		max-width: 40%;
	}
}

.search-container {
	@extend %content-contained;
}

.search-full-width {
	background-color: $search-background-color;
	margin-bottom: 40px;
}
$section-side-padding: 40px;

.section {
    display: block;
    padding: 20px $section-side-padding;
    max-width: $max-page-width;
    margin: 0 auto;

    @include small-breakpoint {
        padding: calc(#{$section-side-padding} / 2);
    }
}

/* this class should be added to the component class, not the component's section wrapper */
.is-full-width {
    width: calc(100% + (2 * #{$section-side-padding}));
    margin-left: -$section-side-padding;

    @include small-breakpoint {
        width: calc(100% + #{$section-side-padding});
        margin-left: calc(#{$section-side-padding} / -2);
    }
}

.section.has-alt-background {
    padding: 20px;
    background-color: $alt-background-color;

    @include medium-breakpoint {
        padding: 20px $section-side-padding;
    }

    @include small-breakpoint {
        padding: 20px;
    }
}

.section.has-background {
    padding: 0;
}


main > .section {
    margin-bottom: 50px;

    @include medium-breakpoint {
        margin-bottom: 0;
    }
}

.container .section:last-child {
    margin-bottom: 0; // the container will take care of bottom margin
}
.rail-container__title {
    font-family: $font-cdmsmith;
    font-size: $font-size-small-3;
    font-style: italic;
    line-height: $font-size-small-3-line;
    background: $color-white;
    padding: 20px 40px 10px;

    // @include small-breakpoint {
    //     padding: 0 20px 10px;
    // }
}

@media only screen and (max-width:799px) {
    .rail-container__title {
        font-size: $font-size-small-2 !important;
        line-height: $font-size-small-2-line !important;
    }
}

.rail-container__content.has-alt-background {
    padding: 20px;
    background-color: $alt-background-color;

    /* if side rail container has alt background,
    and components inside also have alt background,
    those components will have a white background
    (aka alternate to container background)
    */
    .rte-section.has-alt-background,
    .quote-promo.has-alt-background,
    .area-of-focus-section.has-alt-background,
    .event-promo.has-alt-background {
        background-color: $color-white;
    }
}

.rail-container__content.has-alt-background > .section {
    padding: 20px; /* the rest of the padding comes from the rail container */
}

.rail-container__content.has-alt-background > .section.has-background {
    padding: 0; /* padding comes from component */
}

header {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.header--over-banner + main {
    top: 0;
}

header .logo--print {
    position: relative;

    img {
        display: block !important; // to overide hide of all imgs
    }

    a {
        display: block;
        text-decoration: none;
        border-bottom: 0;
        position: relative;
    }

    a:after {
        content: $domain;
        position: relative;
        float: right;
        bottom: 10px;
        font-size: 14px;
    }
}

header .nav {
    display: none;
}
/* Hide banners */
.banner {
    &__outer-wrapper {
        display: none;
    }
    &__image {
        background: none !important;
    }
    &__large-notch {
        display: block;
        position: static;
        margin-top: 0 !important;
        margin-bottom: 0!important;
    }
}

/* Hide related content components */
.teaser-block-section,
.related-experts-section,
.latest-news,
.news-list-section {
    display: none;
}

/* Hide carousels */
.large-carousel-section,
.image-gallery-section,
.person-focus-section,
.people-river-section,
.featured-people-section {
    display: none;
}

/* Hide floating & fullscreen stuff */
.back-to-top,
.cookie-warning,
.search-takeover,
.region-takeover,
.social-share {
    display: none;
}

/* Hide interactive components */
.email-form-section,
.accordion-gallery-section,
.code-embed-section,
.downloads-button-section,
.offices-search__map-section {
    display: none;
}

/* Hide navigation */
.left-page-navigation {
    display: none;
}

/* Hide media components */
.video-section,
.image-section {
    display: none;
}

/* Hide social */
.social-media-latest-news-section {
    display: none;
}

.pullquote {
    @extend %content-contained;
    position: relative;

    &__quote {
        font-family: $font-cdmsmith;
        font-size: $font-size-med-3;
        font-style: italic;
        line-height: $font-size-med-3-line;
        color: $color-brand-teal;
        word-wrap: break-word;
        vertical-align: baseline;

        @include small-breakpoint {
            font-size: $font-size-med;
            line-height: $font-size-med-line;
        }
    }

    &__quotation-surrounded {
        &::before,
        &::after {
            font-size: $font-size-large-3-m;
            vertical-align: bottom;

            @include small-breakpoint {
            font-size: $font-size-large;
            line-height: $font-size-small-line;
            }
        }

        &::before {
            content: $open-quotation-mark;
            margin-right: 5px;
        }

        &::after {
            content: $close-quotation-mark;
            position: relative;
            top: 4px;
            margin-left: 5px;
        }
    }

    &__attribution {
        @extend .heading--small-subtle;
        display: block;
        padding-left: 20px;
        margin-top: 25px;
        font-style: normal;

        @include small-breakpoint {
            margin-top: 15px;
        }

        &::before {
            position: absolute;
            left: 0;
            content: $long-dash $non-break-space;
        }
    }
}

.experience-editor {
    .pullquote__quote::before,
    .pullquote__quote::after {
        display: none;
    }

    .pullquote__quote span[scfieldtype="multi-line text"] {
        @extend .pullquote__quotation-surrounded;
    }
}

.what-we-do {
    display: flex;
    justify-content: space-around;

    @include small-breakpoint {
        flex-direction: column;
    }
}

.what-we-do__block {
    width: 33%;

    @include small-breakpoint {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 25px;
        }
    }
}

.what-we-do__list {
    text-align: center;
    list-style: none;
}

.what-we-do__title {
    @include line-beneath-heading($color-brand-light-blue);
    font-family: $font-cdmsmith;
    font-size: $font-size-med-2;
    font-style: normal;
    line-height: $font-size-med-2-line;
    text-align: center;
}

.what-we-do__list-item {
    margin-bottom: 15px;
}

.what-we-do__list-link {
    @extend %link-on-hover-only;
}

/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/

@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-Regular.woff");
  src: url("../fonts/CDMSmith-Regular.woff") format("woff"),
       url('../fonts/CDMSmith-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-Italic.woff");
  src: url("../fonts/CDMSmith-Italic.woff") format("woff"),
       url('../fonts/CDMSmith-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-Bold.woff");
  src: url("../fonts/CDMSmith-Bold.woff") format("woff"),
       url('../fonts/CDMSmith-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-BoldItalic.woff");
  src: url("../fonts/CDMSmith-BoldItalic.woff") format("woff"),
       url('../fonts/CDMSmith-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith Display";
  src: url("../fonts/CDMSmith-Display.woff");
  src: url("../fonts/CDMSmith-Display.woff") format("woff"),
       url('../fonts/CDMSmith-Display.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith Display";
  src: url("../fonts/CDMSmith-DisplayItalic.woff");
  src: url("../fonts/CDMSmith-DisplayItalic.woff") format("woff"),
       url('../fonts/CDMSmith-DisplayItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith Light";
  src: url("../fonts/CDMSmith-Light.woff");
  src: url("../fonts/CDMSmith-Light.woff") format("woff"),
       url('../fonts/CDMSmith-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CDM Smith Light";
  src: url("../fonts/CDMSmith-LightItalic.woff");
  src: url("../fonts/CDMSmith-LightItalic.woff") format("woff"),
       url('../fonts/CDMSmith-LightItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

// Font Families
$font-sans: 'Stevie Sans W00', "HelveticaNeue", "Helvetica", "Arial", sans-serif !important;
$font-serif: "Georgia", Times, "Times New Roman", serif !important;
$font-cdmsmith: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
$font-cdmsmith-sans: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
$font-cdmsmith-display: "CDM Smith Display", serif;
$font-cdmsmith-light: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial",serif;
$font-cdmsmith-light-sans: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
$font-statistics: $font-cdmsmith, 'URW Alternate Gothic W01', sans-serif !important;

// Text Sizes

//XL 
$font-size-large-3: 72px;
$font-size-large-3-line: 70px;

$font-size-large-3-m: 60px;
$font-size-large-3-m-line: 68px;

//H1
$font-size-large-2: 54px;
$font-size-large-2-line: 62px;

$font-size-large-2-m: 36px;
$font-size-large-2-m-line: 38px;

//H2
$font-size-large: 42px;
$font-size-large-line: 50px;

$font-size-large-m: 32px;
$font-size-large-m-line: 38px;

//H3
$font-size-med-3: 34px;
$font-size-med-3-line: 42px;

//H4
$font-size-med-2: 28px;
$font-size-med-2-line: 36px;

//H5
$font-size-med: 24px;
$font-size-med-line: 32px;

//large body

$font-size-small-3: 20px;
$font-size-small-3-line: 30px;

//H6
$font-size-small-2: 18px;
$font-size-small-2-line: 24px;

//small body
$font-size-small: 14px;
$font-size-small-line: 24px;

$body-cdmsmith-font-size: 16px;
$body-cdmsmith-line: 24px;


$header-font-h1: 54px;
$header-font-h1-line: 68px;

$header-font-h2: 42px;
$header-font-h2-line: 50px;

$header-font-h3: 34px;
$header-font-h3-line: 42px;

$header-font-h4: 28px;
$header-font-h4-line: 36px;

$header-font-h5: 24px;
$header-font-h5-line: 36px;

$header-font-h6: 18px;
$header-font-h6-line: 24px;

.cta-with-image {
    @extend %content-contained;
    position: relative;
    color: $color-white;
    overflow: hidden;
}

.cta-with-image__color-block {
    display: inline-block;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        z-index: 0;
    }
}

.cta-with-image__color-block div {
    position: relative;
    z-index: 1;
}

.cta-with-image__header {
    display: block;
    margin-bottom: 20px;
    font-size: $font-size-med-3;
    line-height: $font-size-med-3-line;
    font-weight: bold;
    color: $color-white;
}

.cta-with-image__summary {
    margin-bottom: 20px;
    font-size: $font-size-small-3;
    line-height: $font-size-small-3-line;
    color: $color-white;
}

@media only screen and (max-width:799px) {
    .cta-with-image .cta-with-image__header{
        font-size: $font-size-med;
        line-height: $font-size-med-line;
    }

    .cta-with-image .cta-with-image__summary{
        font-size: $font-size-small-2;
        line-height: $font-size-small-2-line;
    }
}

.cta-with-image__name,
.cta-with-image__title {
    font-size: $body-cdmsmith-font-size;
    font-weight: 700;
}

.cta-with-image__button {
    @extend .btn--white;
    height: 44px;
    white-space: nowrap;
}

a.cta-with-image__button.btn{
  color: $color-brand-light-blue-acc;
  background-color: $color-white;
  &:hover {
    color: $color-white;
    background-color: $color-brand-light-blue-acc;
    outline: 2px solid $color-white;
  }
}

.cta-with-image__flex-wrap {
    display: flex;
    justify-content: space-between;
}

/*  Note : Had to use mixins for the below styles
    (instead of regular classes or placeholders)
    b/c you can't extend from within the media query
    mixin.
*/

/** 3 Style Versions: Large, Medium Small **/
/** (they don't just correspond to breakpoints) **/

/*  Only for components in main content container,
    at large breakpoint
*/
@mixin cta-with-image--size-large {
    .cta-with-image__color-block {
        position: absolute;
        width: 55%;
        height: 100%;
        padding: 60px 30px 60px 60px;
    }

    .cta-with-image__image {
        block-size: auto;
        display: block;
        object-fit: cover;
        position: relative;
        float: right;
        width: 50%;
    }

    .cta-with-image__button {
        align-self: flex-end;
    }
}

/*  For all components at medium breakpoint,
    and also in 50% container at large breakpoints
*/
@mixin cta-with-image--size-medium {
    .cta-with-image__color-block {
        position: relative;
        top: -150px;
        margin-bottom: -150px;
        width: 100%;
        height: auto;
        max-width: 100%;
        padding: 50px;
    }

    .cta-with-image__image {
        block-size: auto;
        display: block;
        object-fit: cover;
        position: relative;
        width: 100%;
    }

    .cta-with-image__header {
        font-size: $font-size-med-3;
        line-height: $font-size-med-3-line;
        margin-bottom: 20px;
    }

    .cta-with-image__summary {
        font-size: $font-size-small-3;
        line-height: $font-size-small-3-line;
        margin-bottom: 30px;
    }

    .cta-with-image__name,
    .cta-with-image__title {
        font-size: $body-cdmsmith-font-size;
        line-height: $body-cdmsmith-line;
    }

    .cta-with-image__button {
        align-self: flex-end;
        width: auto;
        margin-top: 0;
        margin-bottom: 0;
    }

    .cta-with-image__flex-wrap {
        flex-direction: row;
    }
}

/*  For all components at small breakpoint,
    and also in 30% container at large breakpoints
*/
@mixin cta-with-image--size-small {
    .cta-with-image__color-block {
        top: -75px;
        margin-bottom: -75px;
        width: 100%;
        max-width: 100%;
        padding: 25px;
    }

    .cta-with-image__image {
        block-size: auto;
        display: block;
        object-fit: cover;
        position: relative;
        width: 100%;
    }

    .cta-with-image__header {
        font-size: $font-size-med;
        line-height: $font-size-med-line;
        margin-bottom: 15px;
    }

    .cta-with-image__summary {
        font-size: $body-cdmsmith-font-size;
        line-height: $body-cdmsmith-line;
        margin-bottom: 15px;
    }

    .cta-with-image__name,
    .cta-with-image__title {
        font-size: $body-cdmsmith-font-size;
        line-height: $body-cdmsmith-line;
    }

    .cta-with-image__button {
        align-self: flex-start;
        width: auto;
        margin-top: 20px;
        margin-bottom: 0;
    }

    .cta-with-image__flex-wrap {
        flex-direction: column;
    }
}

/*  Applying the correct styles to the correct
    breakpoints and containers
*/
.cta-with-image {
    @include cta-with-image--size-large();

    @include medium-breakpoint {
        @include cta-with-image--size-medium();
    }

    @include small-breakpoint {
        @include cta-with-image--size-small();
    }

    min-height: 300px;
}

@media only screen and (max-width:799px) {
    .split-container__inner--50 .cta-with-image .cta-with-image__header{
        font-size: $font-size-med;
        line-height: $font-size-med-line;
        margin-bottom: 4px;
    }

    .split-container__inner--50 .cta-without-image .cta-without-image__header{
        font-size: $font-size-med;
        line-height: $font-size-med-line;
        margin-bottom: 4px;
    }
}

.split-container__inner--50 .cta-with-image {
    @include cta-with-image--size-medium();

    @include small-breakpoint {
        @include cta-with-image--size-small();
    }

    .cta-with-image__header {
        font-size: $font-size-med;
        line-height: $font-size-med-line;
    }

    .cta-with-image__summary {
        font-size: $body-cdmsmith-font-size;
        line-height: $body-cdmsmith-line;
    }

    .cta-with-image__name, .cta-with-image__title {
        font-size: $body-cdmsmith-font-size;
        line-height: $body-cdmsmith-line;
    }

}

.split-container__inner--30 .cta-with-image {
    @include cta-with-image--size-small();

    .cta-with-image__color-block {
        height: auto;
        position: relative;
    }

    @include medium-breakpoint {
        @include cta-with-image--size-medium();
    }

    @include small-breakpoint {
        @include cta-with-image--size-small();
    }

    .cta-with-image__header {
      font-size: $font-size-med;
      line-height: $font-size-med-line;
      margin-bottom: 4px;
    }

    .cta-with-image__summary {
      font-size: $body-cdmsmith-font-size;
      line-height: $body-cdmsmith-line;
      margin-bottom: 4px;
    }

    .cta-with-image__name, .cta-with-image__title {
      font-size: $font-size-small;
      line-height: $font-size-small-line;
    }
}

/* Color themes */
.cta-with-image {
    &.theme-light-blue {
        .cta-with-image__color-block::after {
            background: $color-transparent-darker-light-blue;
        }
    }
    &.theme-blue {
        .cta-with-image__color-block::after {
            background: $color-transparent-blue;
        }
    }
    &.theme-green {
        .cta-with-image__color-block::after {
            background: $color-transparent-dark-green;
        }
    }
    &.theme-orange {
        .cta-with-image__color-block::after {
            background: $color-transparent-dark-orange;
        }
    }
    &.theme-teal {
        .cta-with-image__color-block::after {
            background: $color-transparent-dark-teal;
        }
    }
    &.theme-maroon {
        .cta-with-image__color-block::after {
            background: $color-transparent-dark-maroon;
        }
    }
}

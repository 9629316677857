/*  contains styles for 50-50, 30-70 and 70-30 containers */

/*  at medium breakpoint, the container on the right will
    stack beneath the one on the left */

$half-gutter-width: 20px;

main > .container {
    margin-bottom: 50px;

    @include medium-breakpoint {
        margin-bottom: 0;
    }
}

.split-container {
    @extend %content-contained;
    @extend %cf;
    position: relative;
    background-color: $color-white;

    @include medium-breakpoint {
        padding-right: 0;
        padding-left: 0;
    }
}

.split-container__inner {
    display: inline-block;
}

.split-container__inner--70 {
    width: calc(70% - #{$half-gutter-width});
}

.split-container__inner--50 {
    width: calc(50% - #{$half-gutter-width});
}

.split-container__inner--30 {
    width: calc(30% - #{$half-gutter-width});
    hyphens: none;
}

.split-container__inner:first-of-type {
    float: left;
    margin-right: $half-gutter-width;

    @include medium-breakpoint {
        float: none;
        width: 100%;
        margin-right: 0;
        margin-bottom: 0;
    }
}

.cdmsmith-marketo label{
  color: #F0F;
}

.cdmsmith-marketo .mktoFormRow{
  padding: 25px;
}

.split-container__inner:last-of-type {
    margin-left: $half-gutter-width;

    @include medium-breakpoint {
        width: 100%;
        margin-left: 0;
    }
}

// any section titles inside a 30% container
// don't have lines
.split-container__inner--30 {
    .heading--section-title::after {
        content: none; // removes line
    }
}

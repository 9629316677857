

.search-container {
	margin-bottom: 40px;
}

.search-box {
    width: 70%;

	@include small-breakpoint {
		width: 100%;
	}
}

.search-box__input-container {
	position: relative;
}

.search-box__input {
	font-size: $body-cdmsmith-font-size;
	line-height: $body-cdmsmith-line;
	height: 50px;
	width: 100%;
	border: 3px solid $color-gray-15;

	@include small-breakpoint {
		min-width: 0;
	}
}

.search-box__button {
	border: none;
	background-color: transparent;
	padding: 0;
	position: absolute;
		right: 15px;
		top: 50%;
	transform: translateY(-50%);

	.icon {
		height: 24px;
		width: 24px;
	}
}

.section-title {
    @extend %content-contained;
    display: flex;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 5px;
    margin-bottom: 36px;
    overflow: hidden;

    @include small-breakpoint {
        padding-top: 25px;
    }
}

.section-title__heading {
    font-size: $font-size-med-2 !important;
    line-height: $header-font-h4-line !important;
    position: relative;
    padding-left: 30px;
    display: inline;
    font-family: $font-cdmsmith-light-sans !important;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: normal;
    font-style: normal;
    color: $color-text;
}

@media only screen and (max-width:799px) {
  .section-title__heading {
    font-size: $font-size-med !important;
    line-height: $header-font-h3 !important;
    letter-spacing: 1px !important;
  }
}

.split-container__inner--30 .section-title__heading {
    font-size: 22px !important;
    line-height: $header-font-h4-line !important;
    hyphens:none;
}

.section-title__block {
    position: absolute;
    top: 15px;
    left: 0;
    display: inline-block;
    width: 15px;
    height: 10px;
    background-color: $color-brand-green;
}

@media only screen and (max-width:799px) {
  .section-title__block {
    top: 11px !important;
  }
}

.section-title__line {
    position: absolute;
    display: inline-block;
    float: right;
    width: $max-content-width;
    height: 1px;
    margin: 20px 0;
    margin-left: 15px;
    color: $color-white;
    background-color: $color-gray-35;

    @include small-breakpoint {
        display: none;
    }
}

$search-tags-background-color: $color-gray-02;
$search-tags-secondary-color: $color-brand-green;

.search-tags__container {
	@extend %content-contained;
	max-width: 1280px;
	padding: 0 40px;
	
	@include small-breakpoint {
		padding: 0 20px;
	}
}

.search-tags {
	display: inline-block;
	padding-right: 100px;
	width: calc(100% - 288px); // 288px = width of clear all button

	@include medium-breakpoint {
		padding-right: 40px;
	}

	@include small-breakpoint {
		margin-bottom: 20px;
		padding-right: 0;
		width: 100%;
	}
}

.search-tags__clear-all {
	background-color: $search-tags-secondary-color;
	border: none;
	color: $color-white;
	display: inline-block;
	font-size: $font-size-small-2;
	letter-spacing: 3px;
	max-width: 288px;
	padding: 15px 40px;
	text-transform: uppercase;
	vertical-align: top;

	&:hover {
		background-color: darken($search-tags-secondary-color, 5%);
	}

	@include small-breakpoint {
		margin-bottom: 20px;
	}
}

.search-tags__tag {
	background-color: $search-tags-background-color;
	display: inline-block;
	font-weight: 700;
	margin-bottom: 15px;
	margin-right: 15px;
	padding: 10px 50px 10px 30px;
	position: relative;

	&:hover {
		background-color: $search-tags-secondary-color;
		color: $color-white;
		cursor: pointer;

		&:before,
		&:after {
			background-color: $color-white;
		}
	}

	&:last-child {
		margin-right: 0;
	}

	// cross
	&:after,
	&:before {
		background-color: $search-tags-secondary-color;
		content: '';
		display: inline-block;
		position: absolute;
			right: 15px;
			top:50%;
		height: 3px;
		width: 13px;
	}

	&:before {
		transform: translateY(-50%) rotate(-45deg);
	}

	&:after {
		transform: translateY(-50%) rotate(45deg);
	}
}
@charset "UTF-8";
/*------------------------------------*\
    #SCSS FILE IMPORTS FOR PRINT
\*------------------------------------*/
@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-Regular.woff");
  src: url("../fonts/CDMSmith-Regular.woff") format("woff"), url("../fonts/CDMSmith-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-Italic.woff");
  src: url("../fonts/CDMSmith-Italic.woff") format("woff"), url("../fonts/CDMSmith-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-Bold.woff");
  src: url("../fonts/CDMSmith-Bold.woff") format("woff"), url("../fonts/CDMSmith-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-BoldItalic.woff");
  src: url("../fonts/CDMSmith-BoldItalic.woff") format("woff"), url("../fonts/CDMSmith-BoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith Display";
  src: url("../fonts/CDMSmith-Display.woff");
  src: url("../fonts/CDMSmith-Display.woff") format("woff"), url("../fonts/CDMSmith-Display.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith Display";
  src: url("../fonts/CDMSmith-DisplayItalic.woff");
  src: url("../fonts/CDMSmith-DisplayItalic.woff") format("woff"), url("../fonts/CDMSmith-DisplayItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith Light";
  src: url("../fonts/CDMSmith-Light.woff");
  src: url("../fonts/CDMSmith-Light.woff") format("woff"), url("../fonts/CDMSmith-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith Light";
  src: url("../fonts/CDMSmith-LightItalic.woff");
  src: url("../fonts/CDMSmith-LightItalic.woff") format("woff"), url("../fonts/CDMSmith-LightItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

/** Brand Colors  -- do we need these? **/
/* Other color variables -- do we need these? */
/* Font Families */
/* Symbols */
/* Link base paths */
/* Print max width */
/* should always go first */
/* unmodified abstracts */
/*------------------------------------*\
    #BREAKPOINTS
    note:   responsive styles/breakpts
            are written desktop-first
\*------------------------------------*/
/** IMPORTANT:
    breakpoints are hardcoded in the
    cshtml files for all banner components.
    any changes to these variables must be
    replicated there
**/
/*------------------------------------*\
    #MEDIA QUERY MIXINS
    usage:  @include small-breakpoint {
                font-size: 18px;
            }
\*------------------------------------*/
/*------------------------------------*\
    #RESPONSIVE TOGGLE MIXINS
    usage:  @include large-only(flex);
\*------------------------------------*/
/*------------------------------------*\
    #COLORS
\*------------------------------------*/
/** Brand Colors **/
/** Transparent Brand Colors **/
/** Neutral Colors **/
/* Note: Neutral color variables use a numbered system to define variables for better maintainability.
    "$color-gray-10" means "10% gray", which is a gray closer to white than to black. For more info read https://medium.com/eightshapes-llc/color-in-design-systems-a1c80f65fa3#.brevrq67p */
/** Transparent Neutral Colors **/
/** Color Mapping **/
/*------------------------------------*\
    #MIXINS
    note:   see breakpoints.scss
            for media query mixins
\*------------------------------------*/
/*------------------------------------*\
    #BREAKPOINTS
    note:   responsive styles/breakpts
            are written desktop-first
\*------------------------------------*/
/** IMPORTANT:
    breakpoints are hardcoded in the
    cshtml files for all banner components.
    any changes to these variables must be
    replicated there
**/
/*------------------------------------*\
    #MEDIA QUERY MIXINS
    usage:  @include small-breakpoint {
                font-size: 18px;
            }
\*------------------------------------*/
/*------------------------------------*\
    #RESPONSIVE TOGGLE MIXINS
    usage:  @include large-only(flex);
\*------------------------------------*/
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);

  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/*------------------------------------*\
    #RETINA QUERY MIXINS
    usage:  @include retina-screen {
                font-size: 18px;
            }
\*------------------------------------*/
/* 1.5 dpr and above */
/* 1.49 dpr and below */
/*------------------------------------*\
    #PLACEHOLDER SELECTORS

    note: see typography.scss for 
    font size placeholders
\*------------------------------------*/
/*------------------------------------*\
    #BREAKPOINTS
    note:   responsive styles/breakpts
            are written desktop-first
\*------------------------------------*/
/** IMPORTANT:
    breakpoints are hardcoded in the
    cshtml files for all banner components.
    any changes to these variables must be
    replicated there
**/
/*------------------------------------*\
    #MEDIA QUERY MIXINS
    usage:  @include small-breakpoint {
                font-size: 18px;
            }
\*------------------------------------*/
/*------------------------------------*\
    #RESPONSIVE TOGGLE MIXINS
    usage:  @include large-only(flex);
\*------------------------------------*/
/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/
/* Clearfix */
.split-container {
  *zoom: 1; }
  .split-container::before, .split-container::after {
    display: table;
    content: " "; }
  .split-container::after {
    clear: both; }

/* Centered and contained content with max-width*/
.section-title, .split-container, .event-promo, .rte, .pullquote, .title-and-summary, .breadcrumb-wrapper, .image, .offices-search, .statistics-wrapper, .expert-quote-promo, .cta-without-image, .cta-with-image, .search-tags__container, .search-container, .heading--section-title {
  display: block;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; }
  @media (max-width: 799px) {
    .section-title, .split-container, .event-promo, .rte, .pullquote, .title-and-summary, .breadcrumb-wrapper, .image, .offices-search, .statistics-wrapper, .expert-quote-promo, .cta-without-image, .cta-with-image, .search-tags__container, .search-container, .heading--section-title {
      max-width: 100%; } }

/* Limit length of standard line of text for readability */
p, .event-promo__title, .event-promo__date, .cta-without-image__summary {
  max-width: 765px; }

/* Horizontally and vertically center using flexbox */
/* Remove link styling on a link */
/* Remove link styling, except for on hover */
.what-we-do__list-link, .breadcrumb a, .area-of-focus__list-item a {
  border-bottom: 0; }
  .what-we-do__list-link:hover, .breadcrumb a:hover, .area-of-focus__list-item a:hover {
    border-bottom: 2px solid #0475BC; }

/* Remove base list styling */
/* Remove default button styling */
/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-Regular.woff");
  src: url("../fonts/CDMSmith-Regular.woff") format("woff"), url("../fonts/CDMSmith-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-Italic.woff");
  src: url("../fonts/CDMSmith-Italic.woff") format("woff"), url("../fonts/CDMSmith-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-Bold.woff");
  src: url("../fonts/CDMSmith-Bold.woff") format("woff"), url("../fonts/CDMSmith-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith";
  src: url("../fonts/CDMSmith-BoldItalic.woff");
  src: url("../fonts/CDMSmith-BoldItalic.woff") format("woff"), url("../fonts/CDMSmith-BoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith Display";
  src: url("../fonts/CDMSmith-Display.woff");
  src: url("../fonts/CDMSmith-Display.woff") format("woff"), url("../fonts/CDMSmith-Display.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith Display";
  src: url("../fonts/CDMSmith-DisplayItalic.woff");
  src: url("../fonts/CDMSmith-DisplayItalic.woff") format("woff"), url("../fonts/CDMSmith-DisplayItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith Light";
  src: url("../fonts/CDMSmith-Light.woff");
  src: url("../fonts/CDMSmith-Light.woff") format("woff"), url("../fonts/CDMSmith-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "CDM Smith Light";
  src: url("../fonts/CDMSmith-LightItalic.woff");
  src: url("../fonts/CDMSmith-LightItalic.woff") format("woff"), url("../fonts/CDMSmith-LightItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

/* unmodified base styles */
/*------------------------------------*\
    #BODY BASE STYLES
\*------------------------------------*/
html, body {
  min-height: 100%; }

body {
  position: relative;
  overflow-x: hidden;
  font: 16px "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  /*  Note: Font 100% means default browser font size.
        A user's browser settings could change this. */
  color: #222;
  overflow-y: auto;
  background: #fff;
  font-variant-ligatures: none; }

.modal-is-open,
.is-nav-open {
  overflow: hidden; }

/*------------------------------------*\
    #RESET
    Styles taken from reset and
    normalize, with some modifications
\*------------------------------------*/
/** BASE (BUNCH OF ELEMENTS) **/
* {
  box-sizing: border-box; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block; }

html, body, div, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, ol, ul, li, form, legend, label, table, header, footer, nav, section, figure {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  border: 0; }

[hidden], template {
  display: none; }

/** HTML & BODY **/
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif; }

body {
  margin: 0; }

/** IMAGES **/
svg:not(:root) {
  overflow: hidden; }

img {
  border: 0; }

figure {
  margin: 0; }

/** AUDIO & VIDEO **/
audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

/** SPECIAL TEXT ELEMENTS **/
b,
strong {
  font-weight: bold; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

mark {
  background: #ff0;
  color: #000; }

dfn {
  font-style: italic; }

/** BUTTONS **/
button {
  cursor: pointer;
  font-family: sans-serif;
  line-height: normal;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  background: transparent;
  border: 0; }

button[disabled] {
  cursor: default; }

button::-moz-focus-inner {
  border: 0;
  padding: 0; }

/** FORM ELEMENTS **/
input, .select-list select,
.data-list select,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline; }

input, .select-list select,
.data-list select {
  line-height: normal; }

input[disabled], .select-list select[disabled],
.data-list select[disabled] {
  cursor: default; }

input[type="search"]::-webkit-search-cancel-button, .select-list select[type="search"]::-webkit-search-cancel-button,
.data-list select[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
.select-list select[type="search"]::-webkit-search-decoration,
.data-list select[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

input::-moz-focus-inner, .select-list select::-moz-focus-inner,
.data-list select::-moz-focus-inner {
  border: 0;
  padding: 0; }

html input[type="button"], html .select-list select[type="button"], .select-list html select[type="button"],
html .data-list select[type="button"], .data-list html select[type="button"],
input[type="reset"],
.select-list select[type="reset"],
.data-list select[type="reset"],
input[type="submit"],
.select-list select[type="submit"],
.data-list select[type="submit"] {
  cursor: pointer; }

input[type="checkbox"], .select-list select[type="checkbox"],
.data-list select[type="checkbox"],
input[type="radio"],
.select-list select[type="radio"],
.data-list select[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

fieldset {
  min-width: 0;
  padding: 0.01em 0 0;
  margin: 0;
  border: 0; }

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell; }

legend {
  display: table;
  padding: 0;
  border: 0;
  white-space: normal; }

textarea {
  overflow: auto;
  vertical-align: top; }

/** TABLES **/
table {
  border-collapse: collapse;
  border-spacing: 0; }

p {
  font-size: 16px !important;
  line-height: 24px !important;
  margin-bottom: 16px;
  color: #222; }
  @media (max-width: 799px) {
    p {
      font-size: 12.8px;
      line-height: 19.2px; } }

.paragraph--small {
  font-size: 15px; }
  @media (max-width: 799px) {
    .paragraph--small {
      font-size: 16px; } }

.paragraph--splash, .title-and-summary__summary, .full-width-promo__summary {
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  font-size: 21px;
  color: #707070; }
  .paragraph--splash a, .title-and-summary__summary a, .full-width-promo__summary a {
    color: #707070; }

blockquote,
cite {
  display: block;
  font-style: inherit; }

.section-title {
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 5px;
  margin-bottom: 36px;
  overflow: hidden; }
  @media (max-width: 799px) {
    .section-title {
      padding-top: 25px; } }

.section-title__heading {
  font-size: 28px !important;
  line-height: 36px !important;
  position: relative;
  padding-left: 30px;
  display: inline;
  font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", sans-serif !important;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  font-style: normal;
  color: #222; }

@media only screen and (max-width: 799px) {
  .section-title__heading {
    font-size: 24px !important;
    line-height: 34px !important;
    letter-spacing: 1px !important; } }

.split-container__inner--30 .section-title__heading {
  font-size: 22px !important;
  line-height: 36px !important;
  hyphens: none; }

.section-title__block {
  position: absolute;
  top: 15px;
  left: 0;
  display: inline-block;
  width: 15px;
  height: 10px;
  background-color: #78c143; }

@media only screen and (max-width: 799px) {
  .section-title__block {
    top: 11px !important; } }

.section-title__line {
  position: absolute;
  display: inline-block;
  float: right;
  width: 1200px;
  height: 1px;
  margin: 20px 0;
  margin-left: 15px;
  color: #fff;
  background-color: #a5a5a5; }
  @media (max-width: 799px) {
    .section-title__line {
      display: none; } }

a {
  font-weight: 700;
  color: #222;
  text-decoration: none;
  border-bottom: 1px solid #0475BC; }
  a:hover {
    text-decoration: none;
    border-bottom: 2px solid #0475BC; }

.btn {
  display: inline-block;
  min-height: 44px;
  padding: 14px 30px;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 3px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background: #0475BC;
  border-bottom: 0;
  transition: background 0.2s ease;
  max-width: 100%;
  word-wrap: normal; }
  @media (max-width: 799px) {
    .btn {
      width: 100%;
      margin-bottom: 20px; } }
  .btn:hover {
    background: #548a2e;
    border-bottom: 0; }
  .btn:disabled {
    background: #a5a5a5;
    cursor: default; }
    .btn:disabled:hover {
      background: #a5a5a5;
      border-bottom: 0; }
  .btn .fa-external-link {
    color: #fff; }

.btn--blue {
  background: #0475BC; }
  .btn--blue .fa-external-link {
    color: #fff; }
  .btn--blue:hover {
    background: #003087; }
  .btn--blue:focus {
    outline: #024671 solid 2px; }

.btn--white, .cta-without-image.theme-blue .cta-without-image__button, .cta-without-image.theme-light-blue .cta-without-image__button, .cta-without-image.theme-white .cta-without-image__button, .cta-with-image__button {
  color: #003087;
  background: #fff; }
  .btn--white .fa-external-link, .cta-without-image.theme-blue .cta-without-image__button .fa-external-link, .cta-without-image.theme-light-blue .cta-without-image__button .fa-external-link, .cta-without-image.theme-white .cta-without-image__button .fa-external-link, .cta-with-image__button .fa-external-link {
    color: #0475BC; }
  .btn--white:hover, .cta-without-image.theme-blue .cta-without-image__button:hover, .cta-without-image.theme-light-blue .cta-without-image__button:hover, .cta-without-image.theme-white .cta-without-image__button:hover, .cta-with-image__button:hover {
    color: #fff;
    background: #003087;
    background-color: #003087;
    outline: 2px solid #fff; }

.token {
  display: inline-block;
  height: 44px;
  padding: 10px 14px;
  background-color: #f7f9f9;
  border-bottom: 0; }

.token:hover {
  background-color: #78c143;
  border-bottom: 0; }
  .token:hover .token__text {
    color: #fff; }
  .token:hover .token__clear {
    color: #fff;
    border-bottom: 0; }

.token__text {
  padding: 0 10px;
  font-size: 16px;
  font-weight: bold;
  color: #222; }

.token__clear {
  color: #78c143; }

input, .select-list select,
.data-list select {
  height: 41px;
  min-width: 300px;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9; }
  input::-webkit-input-placeholder, .select-list select::-webkit-input-placeholder,
  .data-list select::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #737373;
    opacity: 1; }
  input::-moz-placeholder, .select-list select::-moz-placeholder,
  .data-list select::-moz-placeholder {
    /* Firefox 19+ */
    color: #737373;
    opacity: 1; }
  input:-ms-input-placeholder, .select-list select:-ms-input-placeholder,
  .data-list select:-ms-input-placeholder {
    /* IE 10+ */
    color: #737373;
    opacity: 1; }
  input:-moz-placeholder, .select-list select:-moz-placeholder,
  .data-list select:-moz-placeholder {
    /* Firefox 18- */
    color: #737373;
    opacity: 1; }

.select-list,
.data-list {
  position: relative;
  display: inline-block;
  cursor: pointer; }
  .select-list::before,
  .data-list::before {
    position: absolute;
    right: 40px;
    height: 100%;
    pointer-events: none;
    border-left: 1px solid #d9d9d9;
    content: ""; }
  .select-list::after,
  .data-list::after {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 0;
    height: 0;
    margin-top: -3px;
    pointer-events: none;
    border-color: #78c143 transparent;
    border-style: solid;
    border-width: 6px 6px 0;
    content: ""; }
  .select-list input::-webkit-calendar-picker-indicator, .select-list select::-webkit-calendar-picker-indicator,
  .data-list input::-webkit-calendar-picker-indicator,
  .data-list select::-webkit-calendar-picker-indicator {
    display: none; }
  .select-list select,
  .data-list select {
    padding: 10px 55px 10px 15px;
    appearance: none; }
    .select-list select::-ms-expand,
    .data-list select::-ms-expand {
      display: none; }

label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  text-transform: uppercase; }

textarea {
  padding: 20px;
  border: 1px solid #909090; }

.section {
  display: block;
  padding: 20px 40px;
  max-width: 1600px;
  margin: 0 auto; }
  @media (max-width: 799px) {
    .section {
      padding: calc(40px / 2); } }

/* this class should be added to the component class, not the component's section wrapper */
.is-full-width {
  width: calc(100% + (2 * 40px));
  margin-left: -40px; }
  @media (max-width: 799px) {
    .is-full-width {
      width: calc(100% + 40px);
      margin-left: calc(40px / -2); } }

.section.has-alt-background {
  padding: 20px;
  background-color: #f7f9f9; }
  @media (max-width: 1199px) {
    .section.has-alt-background {
      padding: 20px 40px; } }
  @media (max-width: 799px) {
    .section.has-alt-background {
      padding: 20px; } }

.section.has-background {
  padding: 0; }

main > .section {
  margin-bottom: 50px; }
  @media (max-width: 1199px) {
    main > .section {
      margin-bottom: 0; } }

.container .section:last-child {
  margin-bottom: 0; }

.icon {
  width: 60px;
  height: 60px; }

.icon--googleplus {
  padding: 3px; }

main {
  min-height: 100px; }

.header--over-banner + main {
  position: relative;
  top: -100px; }
  @media (max-width: 799px) {
    .header--over-banner + main {
      top: -75px; } }
  .header--over-banner + main + .footer {
    margin-top: -100px; }
    @media (max-width: 799px) {
      .header--over-banner + main + .footer {
        margin-top: -37.5px; } }

/* unmodified component stylesheets */
/*  contains styles for 50-50, 30-70 and 70-30 containers */
/*  at medium breakpoint, the container on the right will
    stack beneath the one on the left */
main > .container {
  margin-bottom: 50px; }
  @media (max-width: 1199px) {
    main > .container {
      margin-bottom: 0; } }

.split-container {
  position: relative;
  background-color: #fff; }
  @media (max-width: 1199px) {
    .split-container {
      padding-right: 0;
      padding-left: 0; } }

.split-container__inner {
  display: inline-block; }

.split-container__inner--70 {
  width: calc(70% - 20px); }

.split-container__inner--50 {
  width: calc(50% - 20px); }

.split-container__inner--30 {
  width: calc(30% - 20px);
  hyphens: none; }

.split-container__inner:first-of-type {
  float: left;
  margin-right: 20px; }
  @media (max-width: 1199px) {
    .split-container__inner:first-of-type {
      float: none;
      width: 100%;
      margin-right: 0;
      margin-bottom: 0; } }

.cdmsmith-marketo label {
  color: #F0F; }

.cdmsmith-marketo .mktoFormRow {
  padding: 25px; }

.split-container__inner:last-of-type {
  margin-left: 20px; }
  @media (max-width: 1199px) {
    .split-container__inner:last-of-type {
      width: 100%;
      margin-left: 0; } }

.split-container__inner--30 .heading--section-title::after {
  content: none; }

.rail-container__title {
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  font-size: 20px;
  font-style: italic;
  line-height: 30px;
  background: #fff;
  padding: 20px 40px 10px; }

@media only screen and (max-width: 799px) {
  .rail-container__title {
    font-size: 18px !important;
    line-height: 24px !important; } }

.rail-container__content.has-alt-background {
  padding: 20px;
  background-color: #f7f9f9;
  /* if side rail container has alt background,
    and components inside also have alt background,
    those components will have a white background
    (aka alternate to container background)
    */ }
  .rail-container__content.has-alt-background .rte-section.has-alt-background,
  .rail-container__content.has-alt-background .quote-promo.has-alt-background,
  .rail-container__content.has-alt-background .area-of-focus-section.has-alt-background,
  .rail-container__content.has-alt-background .event-promo.has-alt-background {
    background-color: #fff; }

.rail-container__content.has-alt-background > .section {
  padding: 20px;
  /* the rest of the padding comes from the rail container */ }

.rail-container__content.has-alt-background > .section.has-background {
  padding: 0;
  /* padding comes from component */ }

.what-we-do {
  display: flex;
  justify-content: space-around; }
  @media (max-width: 799px) {
    .what-we-do {
      flex-direction: column; } }

.what-we-do__block {
  width: 33%; }
  @media (max-width: 799px) {
    .what-we-do__block {
      width: 100%; }
      .what-we-do__block:not(:last-child) {
        margin-bottom: 25px; } }

.what-we-do__list {
  text-align: center;
  list-style: none; }

.what-we-do__title {
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  font-size: 28px;
  font-style: normal;
  line-height: 36px;
  text-align: center; }
  .what-we-do__title::after {
    display: block;
    width: 70px;
    height: 2px;
    margin: 20px auto;
    background-color: #0475BC;
    content: ""; }

.what-we-do__list-item {
  margin-bottom: 15px; }

/* restyled */
.event-promo {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1199px) {
    .event-promo {
      display: block; } }

.event-promo.has-alt-background {
  background: #f7f9f9;
  padding: 20px; }

.event-promo__text {
  margin-right: 40px; }
  @media (max-width: 1199px) {
    .event-promo__text {
      margin-right: 0; } }

.event-promo__title {
  margin-bottom: 15px; }

.event-promo__date {
  margin-bottom: 10px;
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  color: #0475BC; }

@media only screen and (max-width: 799px) {
  .event-promo__date {
    font-size: 24px;
    line-height: 24px; } }

.event-promo__summary {
  margin-bottom: 30px; }

.event-promo__button {
  align-self: flex-end;
  margin-bottom: 15px;
  white-space: nowrap;
  color: #fff;
  background-color: #0475BC; }
  .event-promo__button:hover {
    color: #0475BC;
    background-color: #fff;
    outline: 2px solid #0475BC; }

.split-container .event-promo {
  display: block; }
  .split-container .event-promo .event-promo__text {
    margin-right: 0; }

.split-container__inner--30 .event-promo .event-promo__button {
  width: 100%; }
  @media (max-width: 1199px) {
    .split-container__inner--30 .event-promo .event-promo__button {
      width: auto; } }

/* restyled */
.photo-caption {
  /* slick slider styles */ }
  .photo-caption.slick-dotted {
    margin-bottom: 70px; }
  .photo-caption .slick-disabled {
    visibility: hidden; }
  .photo-caption .slick-dots {
    bottom: -30px; }
    .photo-caption .slick-dots .slick-active {
      background-color: #78c143; }
    .photo-caption .slick-dots li {
      height: auto;
      width: auto;
      background-color: #d9d9d9; }
    .photo-caption .slick-dots button {
      height: 10px;
      width: 18px;
      padding: 0; }
      .photo-caption .slick-dots button:before {
        display: none; }
  @media (max-width: 520px) {
    .photo-caption .slick-arrow {
      width: 30px; } }

.photo-caption__description {
  background-color: #231f20;
  color: #fff;
  padding: 20px 40px;
  position: relative;
  width: 100%; }
  .photo-caption__description p {
    max-width: none; }
  @media (min-width: 1200px) {
    .photo-caption__description {
      background-color: rgba(35, 31, 32, 0.8);
      padding: 10px 40px;
      position: absolute;
      bottom: 0; } }

.photo-caption__slide {
  position: relative; }
  .experience-editor .photo-caption__slide {
    margin-bottom: 20px;
    min-height: 150px; }

.photo-caption__picture {
  block-size: 100%;
  inline-size: 100%;
  inset: 0;
  position: absolute; }
  .photo-caption__picture img {
    block-size: 100%;
    inline-size: 100%;
    object-fit: cover; }

.photo-caption__summary {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0; }

.photo-caption__copyright {
  font-size: 12px;
  font-style: italic;
  line-height: 20px; }

.photo-caption__image {
  position: relative;
  background-position: center;
  background-size: cover; }
  .photo-caption__image::before {
    display: block;
    width: 100%;
    padding-top: 56.25%;
    content: ""; }
  .photo-caption__image > .content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.opportunities {
  position: relative; }

/* Using background image */
.opportunities__image-container {
  position: relative;
  max-width: 80%; }
  .opportunities__image-container::before {
    display: block;
    width: 100%;
    padding-top: 75%;
    content: ""; }
  .opportunities__image-container > .content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  @media (max-width: 1199px) {
    .opportunities__image-container {
      max-width: 100%; } }
  @media (max-width: 799px) {
    .opportunities__image-container {
      position: relative;
      width: 100%;
      margin-right: 0; }
      .opportunities__image-container::before {
        display: block;
        width: 100%;
        padding-top: 133.33333%;
        content: ""; }
      .opportunities__image-container > .content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; } }

.opportunities__image-picture {
  inset: 0;
  position: absolute; }
  .opportunities__image-picture img {
    block-size: 100%;
    inline-size: 100%;
    object-fit: cover; }

.opportunities__rte-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 1200px;
  padding: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  @media (max-width: 1199px) {
    .opportunities__rte-container {
      display: block;
      margin-top: -150px;
      padding-bottom: 0;
      padding-top: 0;
      position: relative; } }
  @media (max-width: 500px) {
    .opportunities__rte-container {
      display: block;
      padding: 0; } }

.opportunities__rte {
  display: block;
  padding: 70px;
  background: rgba(0, 48, 135, 0.8);
  max-width: 60%;
  max-height: 100%;
  color: #fff; }
  .opportunities__rte * {
    color: #fff; }
  @media (max-width: 1199px) {
    .opportunities__rte {
      padding: 70px;
      max-width: 100%; } }
  @media (max-width: 799px) {
    .opportunities__rte {
      height: auto;
      max-height: none;
      max-width: 100%;
      padding: 20px;
      box-sizing: content-box; } }

/* restyled */
/*  NOTE: To make a CSS class appear in the RTE dropdiwn list,
    the style needs to be added to /web/Website/cdmSmith-rte.css
    (hoping to change this in the future)
*/
.rte img {
  height: auto;
  max-width: 100%; }

.rte ul, .rte ol {
  padding-left: 20px;
  margin-bottom: 16px; }

.rte .section-title-style-snippet {
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  font-size: 24px;
  font-style: italic;
  font-weight: normal;
  line-height: 32px;
  color: #222; }

.rte .image-float-right {
  float: right;
  height: auto;
  max-width: 33%;
  margin: 5px 0 10px 10px; }
  @media (max-width: 799px) {
    .rte .image-float-right {
      float: none;
      margin: 10px 0;
      max-width: 100%; } }

.rte .image-float-left {
  float: left;
  height: auto;
  max-width: 33%;
  margin: 5px 10px 10px 0; }
  @media (max-width: 799px) {
    .rte .image-float-left {
      float: none;
      margin: 10px 0;
      max-width: 100%; } }

.rte .image-caption-float-right {
  float: right;
  margin: 5px 0 10px 10px;
  width: 33%; }
  @media (max-width: 799px) {
    .rte .image-caption-float-right {
      float: none;
      margin: 10px 0;
      width: 100%; } }

.rte .image-caption-float-left {
  float: left;
  margin: 5px 10px 10px 0;
  width: 33%; }
  @media (max-width: 799px) {
    .rte .image-caption-float-left {
      float: none;
      margin: 10px 0;
      width: 100%; } }

.rte .image-caption-float-left span,
.rte .image-caption-float-right span {
  font-size: 14px; }

.rte .image-caption-float-left img,
.rte .image-caption-float-right img {
  height: auto;
  margin-bottom: 5px;
  width: 100%; }

.rte h2 {
  display: block;
  font-size: 42px;
  line-height: 50px;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold; }

.pullquote {
  position: relative; }
  .pullquote__quote {
    font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
    font-size: 34px;
    font-style: italic;
    line-height: 42px;
    color: #009999;
    word-wrap: break-word;
    vertical-align: baseline; }
    @media (max-width: 799px) {
      .pullquote__quote {
        font-size: 24px;
        line-height: 32px; } }
  .pullquote__quotation-surrounded::before, .experience-editor .pullquote__quote span[scfieldtype="multi-line text"]::before, .pullquote__quotation-surrounded::after, .experience-editor .pullquote__quote span[scfieldtype="multi-line text"]::after {
    font-size: 60px;
    vertical-align: bottom; }
    @media (max-width: 799px) {
      .pullquote__quotation-surrounded::before, .experience-editor .pullquote__quote span[scfieldtype="multi-line text"]::before, .pullquote__quotation-surrounded::after, .experience-editor .pullquote__quote span[scfieldtype="multi-line text"]::after {
        font-size: 42px;
        line-height: 24px; } }
  .pullquote__quotation-surrounded::before, .experience-editor .pullquote__quote span[scfieldtype="multi-line text"]::before {
    content: "“";
    margin-right: 5px; }
  .pullquote__quotation-surrounded::after, .experience-editor .pullquote__quote span[scfieldtype="multi-line text"]::after {
    content: "”";
    position: relative;
    top: 4px;
    margin-left: 5px; }
  .pullquote__attribution {
    display: block;
    padding-left: 20px;
    margin-top: 25px;
    font-style: normal; }
    @media (max-width: 799px) {
      .pullquote__attribution {
        margin-top: 15px; } }
    .pullquote__attribution::before {
      position: absolute;
      left: 0;
      content: "–" " "; }

.experience-editor .pullquote__quote::before,
.experience-editor .pullquote__quote::after {
  display: none; }

.title-and-summary__detail {
  margin-bottom: 20px;
  margin-top: 12px;
  color: #0475BC; }

.title-and-summary__pipe {
  margin: 0 10px;
  color: #d9d9d9; }

.title-and-summary__divider-line {
  display: block;
  width: 100px;
  height: 4px;
  margin: 15px 0;
  background-color: #0475BC !important; }

.title-and-summary__summary {
  font-size: 20px;
  line-height: 30px;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif !important; }
  @media (max-width: 799px) {
    .title-and-summary__summary {
      font-size: 18px !important;
      line-height: 24px !important; } }

.title-and-summary__type {
  background-color: #d9d9d9;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  padding: 4px 18px;
  text-transform: uppercase;
  margin-bottom: 15px; }

.title-and-summary__subtitle {
  color: #222;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  margin-top: 10px;
  margin-bottom: 15px; }

.title-and-summary__h2Title {
  display: block;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  font-size: 42px;
  line-height: 50px;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold; }
  @media (max-width: 799px) {
    .title-and-summary__h2Title {
      font-size: 32px;
      line-height: 38px; } }

h1.title-and-summary__title {
  display: block;
  font-size: 42px;
  line-height: 50px;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold; }
  @media (max-width: 799px) {
    h1.title-and-summary__title {
      font-size: 32px;
      line-height: 38px; } }

/* restyled */
@media (max-width: 799px) {
  .breadcrumb-wrapper {
    padding: 15px 20px; } }

main > .breadcrumb-wrapper {
  margin-bottom: 0; }

.breadcrumb {
  max-width: 1200px;
  margin: 0 auto;
  list-style: none; }
  @media (max-width: 799px) {
    .breadcrumb {
      max-width: 100%; } }

.breadcrumb li {
  display: inline-block;
  font-size: 14px; }

.breadcrumb__divider {
  display: inline-block;
  font-size: 14px;
  margin: 0 7px 0 10px; }

.breadcrumb li.current {
  color: #0475BC !important; }

.breadcrumb a {
  border-bottom: 2px solid #fff;
  display: inline-block;
  font-size: 14px; }

/* restyled */
.image {
  margin: 0 auto;
  text-align: center; }

.image__wrapper {
  position: relative;
  display: inline-block;
  max-width: 100%; }
  .image__wrapper img {
    block-size: auto;
    max-width: 100%;
    max-height: 600px;
    object-fit: cover; }
    @media (max-width: 799px) {
      .image__wrapper img {
        max-height: 80vh; } }

.image__copyright {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  font-size: 14px;
  line-height: 24px; }
  @media (max-width: 799px) {
    .image__copyright {
      padding: 5px;
      font-size: 14px;
      line-height: 24px; } }

.object-fit--cover {
  font-family: 'object-fit: cover'; }

/* restyled */
.focus-area-section {
  padding: 40px; }
  @media (max-width: 799px) {
    .focus-area-section {
      padding: 20px; } }

.focus-area-section__content {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  width: 100%; }

.focus-area-section__title {
  color: #fff;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  font-size: 22px;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 18px; }

.focus-items {
  display: flex;
  flex-wrap: wrap; }

.focus-item-teaser {
  border-top-color: #0475BC;
  border-top-style: solid;
  border-top-width: 4px;
  font-size: 16px;
  margin-bottom: 20px;
  flex-grow: 0;
  margin: 0 40px;
  padding: 37px 0;
  position: relative;
  width: calc(50% - 80px); }
  .focus-item-teaser:nth-child(odd) {
    margin-left: 0; }
  .focus-item-teaser:nth-child(even) {
    margin-right: 0; }
  @media (max-width: 799px) {
    .focus-item-teaser {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      padding: 20px 0; } }

.focus-item-teaser__title {
  color: #fff;
  font-size: 34px;
  line-height: 42px;
  letter-spacing: 0px;
  margin-bottom: 5px; }

.focus-item-teaser__summary {
  color: #fff;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px; }

@media only screen and (max-width: 799px) {
  .focus-item-teaser__title {
    font-size: 24px;
    line-height: 32px; }
  .focus-item-teaser__summary {
    font-size: 18px;
    line-height: 24px; } }

.focus-item-teaser__button-space-saver {
  width: 100%;
  height: 40px; }

.focus-item-teaser__button-wrapper {
  display: block;
  width: 100%;
  text-align: right; }

.focus-item-teaser__button {
  border-bottom: none;
  color: #fff;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  vertical-align: baseline; }
  .focus-item-teaser__button:hover {
    border-bottom: none; }
  .focus-item-teaser__button:after {
    background-color: #0475BC;
    color: #fff;
    content: "›";
    display: inline-block;
    font-size: 40px;
    font-weight: 100;
    height: 40px;
    line-height: 35px;
    margin-left: 15px;
    position: relative;
    bottom: -1px;
    text-align: center;
    width: 40px;
    vertical-align: middle; }

.focus-area-section--theme-blue {
  background-color: #003087; }
  .focus-area-section--theme-blue .focus-area-section__title {
    color: #fff;
    margin-top: 20px; }
  .focus-area-section--theme-blue .focus-item-teaser {
    border-top-color: #0475BC; }
  .focus-area-section--theme-blue .focus-item-teaser__title {
    color: #fff; }
  .focus-area-section--theme-blue .focus-item-teaser__summary {
    color: #fff; }
  .focus-area-section--theme-blue .focus-item-teaser__button-space-saver {
    display: none; }
  .focus-area-section--theme-blue .focus-item-teaser__button-wrapper {
    position: static; }
  .focus-area-section--theme-blue .focus-item-teaser__button {
    color: #fff; }

.focus-area-section--theme-white {
  background-color: #fff; }
  .focus-area-section--theme-white .focus-area-section__title {
    color: #000; }
  .focus-area-section--theme-white .focus-item-teaser {
    border-top-color: #0475BC;
    border-bottom: 2px solid #d9d9d9;
    padding-bottom: 0;
    margin-bottom: 40px; }
  .focus-area-section--theme-white .focus-item-teaser__title {
    color: #000; }
  .focus-area-section--theme-white .focus-item-teaser__summary {
    color: #444; }
  .focus-area-section--theme-white .focus-item-teaser__button-space-saver {
    display: block; }
  .focus-area-section--theme-white .focus-item-teaser__button-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%; }
  .focus-area-section--theme-white .focus-item-teaser__button {
    color: #0475BC;
    font-weight: bold; }

/* restyled */
/* Search UI */
.offices-search__ui {
  display: block; }

.offices-ui__row {
  display: flex;
  margin-top: 80px; }
  .offices-ui__row:first-child {
    margin-top: 0; }
  @media (max-width: 799px) {
    .offices-ui__row {
      flex-direction: column;
      margin-top: 0; } }

.offices-ui__column {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%; }
  .offices-ui__column:first-of-type {
    margin-right: 40px; }
  @media (max-width: 799px) {
    .offices-ui__column {
      flex-basis: auto;
      margin-bottom: 50px; } }

.offices-ui__title,
.offices-ui__label {
  font-size: 28px;
  line-height: 36px; }

.offices-ui__title {
  margin-bottom: 30px; }

.offices-ui__label {
  font-weight: 700;
  text-transform: none;
  color: #222;
  margin-bottom: 10px; }

.offices-ui__help-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-transform: none;
  color: #222;
  margin-bottom: 20px; }

.offices-ui__select-list__wrapper {
  display: block; }
  .offices-ui__select-list__wrapper select {
    width: 100%;
    min-width: 100px; }

.offices-ui__statement {
  margin-top: 30px;
  margin-bottom: 30px; }

.offices-ui__results-wrapper {
  width: 100%; }

.offices-ui__results-list {
  display: flex;
  flex-wrap: wrap; }

/* Single office contact info */
.office__section {
  display: block;
  font-size: 16px;
  line-height: 24px; }

.office__number-label,
.office__email-label {
  font-weight: 700;
  margin-right: 5px; }

/* Special Offices (headquarters & nearest) */
.offices-ui__row--special-offices .office__wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.offices-ui__row--special-offices .office__section {
  flex-grow: 1;
  flex-shrink: 1; }
  .offices-ui__row--special-offices .office__section:first-of-type {
    margin-right: 10px; }
  @media (max-width: 799px) {
    .offices-ui__row--special-offices .office__section {
      flex-basis: 100%; } }

.offices-ui__row--special-offices .office__name {
  display: none; }

/* Searched Offices */
.offices-ui__row--searched-offices .offices-ui__label {
  margin-bottom: 30px; }

.offices-ui__row--searched-offices .office__wrapper {
  display: none; }

.offices-ui__row--searched-offices .office__wrapper.is-a-result {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 20px;
  margin-bottom: 40px; }
  @media (min-width: 1200px) {
    .offices-ui__row--searched-offices .office__wrapper.is-a-result {
      flex-basis: calc(100% / 3); } }
  @media (max-width: 1199px) {
    .offices-ui__row--searched-offices .office__wrapper.is-a-result {
      flex-basis: calc(50% - 20px); } }
  @media (max-width: 799px) {
    .offices-ui__row--searched-offices .office__wrapper.is-a-result {
      flex-basis: 100%; }
      .offices-ui__row--searched-offices .office__wrapper.is-a-result .office__section {
        padding-right: 0; } }

.offices-ui__row--searched-offices .office__name {
  font-weight: 700;
  margin-bottom: 10px; }

.offices-ui__row--searched-offices .office__email a {
  font-weight: 400; }

/* restyled */
.full-width-promo {
  position: relative;
  display: flex;
  align-items: center;
  height: 500px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 40px; }
  @media (max-width: 799px) {
    .full-width-promo {
      height: 300px;
      padding: 20px; } }
  .full-width-promo::before {
    background-image: linear-gradient(to left, transparent 0%, rgba(0, 48, 135, 0.8) 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1; }
    @media (max-width: 799px) {
      .full-width-promo::before {
        background-color: rgba(0, 48, 135, 0.8);
        opacity: 0.7; } }

.full-width-promo__content {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: 1; }
  .full-width-promo__content > * {
    max-width: 50%; }
    @media (max-width: 1199px) {
      .full-width-promo__content > * {
        max-width: 70%; } }
    @media (max-width: 799px) {
      .full-width-promo__content > * {
        max-width: 100%; } }

.full-width-promo__headline {
  font-size: 34px;
  line-height: 42px;
  font-weight: 700;
  color: #fff; }
  @media (max-width: 799px) {
    .full-width-promo__headline {
      font-size: 24px;
      line-height: 32px;
      width: 100%;
      padding: 0; } }

.full-width-promo__divider-line {
  display: block;
  width: 100px;
  height: 4px;
  margin: 15px 0;
  background-color: #009999; }

.full-width-promo__picture {
  inset: 0;
  position: absolute; }
  .full-width-promo__picture img {
    block-size: 100%;
    inline-size: 100%;
    object-fit: cover; }

.full-width-promo__summary {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", sans-serif !important; }
  .full-width-promo__summary.desktop {
    display: block;
    font-size: 18px; }
  .full-width-promo__summary.mobile {
    display: none; }
  @media (max-width: 799px) {
    .full-width-promo__summary {
      font-size: 18px;
      line-height: 24px; }
      .full-width-promo__summary.desktop {
        display: none; }
      .full-width-promo__summary.mobile {
        display: block; } }

@media only screen and (max-width: 799px) {
  .full-width-promo__headline {
    font-size: 24px;
    line-height: 32px; }
  .full-width-promo__summary {
    font-size: 18px;
    line-height: 24px; } }

.full-width-promo__btn {
  margin-top: 24px; }

a.full-width-promo__btn.btn.btn--white, .cta-without-image.theme-blue a.full-width-promo__btn.btn.cta-without-image__button, .cta-without-image.theme-light-blue a.full-width-promo__btn.btn.cta-without-image__button, .cta-without-image.theme-white a.full-width-promo__btn.btn.cta-without-image__button, a.full-width-promo__btn.btn.cta-with-image__button {
  color: #0475BC;
  background-color: #fff; }
  a.full-width-promo__btn.btn.btn--white:hover, .cta-without-image.theme-blue a.full-width-promo__btn.btn.cta-without-image__button:hover, .cta-without-image.theme-light-blue a.full-width-promo__btn.btn.cta-without-image__button:hover, .cta-without-image.theme-white a.full-width-promo__btn.btn.cta-without-image__button:hover, a.full-width-promo__btn.btn.cta-with-image__button:hover {
    color: #fff;
    background-color: #0475BC;
    outline: 2px solid #fff; }

/* restyled */
/* styles shared by both horizontal and vertical displays */
.statistics-wrapper {
  overflow: hidden;
  position: relative;
  margin: 0 auto; }

.statistic {
  padding: 40px 20px;
  text-align: center;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }

.statistic__picture {
  block-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 50%;
  position: absolute;
  transform: translate(-50%); }
  .statistic__picture img {
    block-size: 100%; }

.statistic__super {
  font-size: 42px; }

.statistic__counter-number {
  position: relative; }

.statistic__super,
.statistic__counter-number {
  display: inline;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif, "URW Alternate Gothic W01", sans-serif !important !important;
  color: #0475BC !important;
  font-weight: 700 !important; }

.statistic__summary {
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  max-width: 300px;
  margin: 0px auto 0 !important;
  color: #222;
  position: relative; }

.statistics-wrapper .statistics-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: stretch;
  flex-direction: row;
  align-items: stretch;
  margin-left: -2px; }
  @media (min-width: 1200px) {
    .statistics-wrapper .statistics-list .split-container__inner--30 {
      display: block; } }

.statistics-wrapper .statistic {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  margin-bottom: 20px;
  position: relative;
  width: 245px; }
  @media (min-width: 1200px) {
    .split-container__inner--30 .statistics-wrapper .statistic {
      flex-basis: 100%;
      padding: 0 20px 20px; } }
  .statistics-wrapper .statistic:before {
    background-color: #d9d9d9;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 130px;
    width: 2px;
    z-index: 1; }

.statistics-wrapper .statistic__super {
  font-size: 52px; }
  @media (max-width: 799px) {
    .statistics-wrapper .statistic__super {
      font-size: 42px;
      padding: 20px 0; } }
  @media (min-width: 1200px) {
    .split-container__inner--30 .statistics-wrapper .statistic__super {
      font-size: 42px; } }

.statistics-wrapper .statistic__counter-number {
  font-size: 72px;
  line-height: 70px; }
  @media (max-width: 799px) {
    .statistics-wrapper .statistic__counter-number {
      font-size: 60px;
      line-height: 68px; } }
  @media (min-width: 1200px) {
    .split-container__inner--30 .statistics-wrapper .statistic__counter-number {
      font-size: 54px;
      line-height: 62px; } }

@media only screen and (max-width: 799px) {
  .statistics-wrapper .statistic__counter-number {
    font-size: 60px;
    line-height: 68px; } }

/* restyled */
.area-of-focus__title {
  margin-bottom: 18px;
  font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
  font-size: 28px;
  line-height: 36px;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px; }

@media only screen and (max-width: 799px) {
  .area-of-focus__title {
    font-size: 24px !important;
    line-height: 32px !important; } }

.area-of-focus__list {
  list-style: none; }

.area-of-focus__list-item {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 24px; }
  .area-of-focus__list-item a {
    color: #0475BC;
    border-bottom: 1px solid; }
    .area-of-focus__list-item a:hover {
      color: #0475BC;
      border-bottom: 3px solid #0475BC !important; }
  .area-of-focus__list-item .btn {
    color: #fff;
    background-color: #0475BC; }
    .area-of-focus__list-item .btn:hover {
      color: #0475BC;
      background-color: #fff;
      outline: 2px solid #0475BC;
      border-bottom: 0px; }

@media only screen and (max-width: 799px) {
  .area-of-focus__title {
    font-size: 24px;
    line-height: 32px; }
  .area-of-focus__list-item {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px; } }

.split-container__inner--30 .area-of-focus__title {
  font-size: 20px;
  line-height: 30px; }

/* component wraps into 2 columns in 50-50 container */
.split-container__inner--50 .area-of-focus__list {
  display: flex;
  flex-wrap: wrap; }
  .split-container__inner--50 .area-of-focus__list > * {
    width: calc(50% - 10px); }
  .split-container__inner--50 .area-of-focus__list > *:nth-child(odd) {
    margin-right: 20px; }
  .split-container__inner--50 .area-of-focus__list > *:only-child {
    width: 100%;
    margin-right: 0; }
  @media (max-width: 799px) {
    .split-container__inner--50 .area-of-focus__list > *,
    .split-container__inner--50 .area-of-focus__list > *:nth-child(odd),
    .split-container__inner--50 .area-of-focus__list > *:only-child {
      width: 100%;
      margin-right: 0; } }

/* restyled */
.expert-quote-promo {
  font-size: 16px !important;
  position: relative;
  color: #fff;
  overflow: hidden; }

.expert-quote-promo__color-block {
  position: relative;
  display: inline-block;
  width: 55%; }
  .expert-quote-promo__color-block::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 0; }

.expert-quote-promo__color-block div {
  position: relative;
  z-index: 1; }

.expert-quote-promo__quote {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  color: #fff; }
  .expert-quote-promo__quote:last-child {
    margin-bottom: 0; }

.expert-quote-promo__title {
  font-size: 14px;
  font-weight: normal !important;
  color: #fff;
  margin-top: 5px; }

.expert-quote-promo__name {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 5px; }

a.expert-quote-promo__name {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid #fff !important;
  text-decoration: none !important; }
  a.expert-quote-promo__name:hover {
    color: #fff;
    border-bottom: 3px solid #fff !important; }

.expert-quote-promo__flex-wrap {
  display: flex;
  justify-content: space-between; }

/*  Note : Had to use mixins for the below styles
    (instead of regular classes or placeholders)
    b/c you can't extend from within the media query
    mixin.
*/
/** 3 Style Versions: Large, Medium Small **/
/** (they don't just correspond to breakpoints) **/
/*  Only for components in main content container,
    at large breakpoint
*/
/*  For all components at medium breakpoint,
    and also in 50% container at large breakpoints
*/
/*  For all components at small breakpoint,
    and also in 30% container at large breakpoints
*/
/*  Applying the correct styles to the correct
    breakpoints and containers
*/
.expert-quote-promo .expert-quote-promo__color-block {
  padding: 60px 30px 60px 60px; }

.expert-quote-promo .expert-quote-promo__quote {
  font-size: 20px;
  line-height: 28px; }

.expert-quote-promo .fa-quote-left {
  position: absolute;
  top: -40px;
  left: -20px;
  display: block;
  color: rgba(255, 255, 255, 0.3); }

.expert-quote-promo .expert-quote-promo__name,
.expert-quote-promo .expert-quote-promo__title {
  font-size: 14px;
  line-height: 24px; }

.expert-quote-promo .expert-quote-promo__image {
  block-size: auto;
  display: block;
  object-fit: cover;
  position: absolute;
  right: 0;
  min-width: 50%; }

@media (max-width: 1199px) {
  .expert-quote-promo .expert-quote-promo__color-block {
    top: -190px;
    margin-bottom: -190px;
    width: 100%;
    max-width: 100%;
    padding: 50px; }
  .expert-quote-promo .expert-quote-promo__quote {
    font-size: 16px;
    line-height: 24px; }
  .expert-quote-promo .fa-quote-left {
    display: block; }
  .expert-quote-promo .expert-quote-promo__name,
  .expert-quote-promo .expert-quote-promo__title {
    font-size: 16px;
    line-height: 24px; }
  .expert-quote-promo .expert-quote-promo__image {
    block-size: auto;
    display: block;
    object-fit: cover;
    position: relative;
    width: 100%; } }

@media (max-width: 799px) {
  .expert-quote-promo .expert-quote-promo__color-block {
    top: -100px;
    margin-bottom: -100px;
    width: 100%;
    max-width: 100%;
    padding: 20px; }
  .expert-quote-promo .expert-quote-promo__quote {
    font-size: 16px;
    line-height: 24px; }
  .expert-quote-promo .fa-quote-left {
    font-size: 40px;
    top: -15px;
    left: -13px; }
  .expert-quote-promo .expert-quote-promo__name,
  .expert-quote-promo .expert-quote-promo__title {
    font-size: 14px;
    line-height: 24px; }
  .expert-quote-promo .expert-quote-promo__image {
    block-size: auto;
    display: block;
    object-fit: cover;
    position: relative;
    width: 100%; } }

.split-container__inner--50 .expert-quote-promo .expert-quote-promo__color-block {
  top: -190px;
  margin-bottom: -190px;
  width: 100%;
  max-width: 100%;
  padding: 50px; }

.split-container__inner--50 .expert-quote-promo .expert-quote-promo__quote {
  font-size: 16px;
  line-height: 24px; }

.split-container__inner--50 .expert-quote-promo .fa-quote-left {
  display: block; }

.split-container__inner--50 .expert-quote-promo .expert-quote-promo__name,
.split-container__inner--50 .expert-quote-promo .expert-quote-promo__title {
  font-size: 16px;
  line-height: 24px; }

.split-container__inner--50 .expert-quote-promo .expert-quote-promo__image {
  block-size: auto;
  display: block;
  object-fit: cover;
  position: relative;
  width: 100%; }

@media (max-width: 799px) {
  .split-container__inner--50 .expert-quote-promo .expert-quote-promo__color-block {
    top: -100px;
    margin-bottom: -100px;
    width: 100%;
    max-width: 100%;
    padding: 20px; }
  .split-container__inner--50 .expert-quote-promo .expert-quote-promo__quote {
    font-size: 16px;
    line-height: 24px; }
  .split-container__inner--50 .expert-quote-promo .fa-quote-left {
    font-size: 40px;
    top: -15px;
    left: -13px; }
  .split-container__inner--50 .expert-quote-promo .expert-quote-promo__name,
  .split-container__inner--50 .expert-quote-promo .expert-quote-promo__title {
    font-size: 14px;
    line-height: 24px; }
  .split-container__inner--50 .expert-quote-promo .expert-quote-promo__image {
    block-size: auto;
    display: block;
    object-fit: cover;
    position: relative;
    width: 100%; } }

.split-container__inner--30 .expert-quote-promo .expert-quote-promo__color-block {
  top: -100px;
  margin-bottom: -100px;
  width: 100%;
  max-width: 100%;
  padding: 20px; }

.split-container__inner--30 .expert-quote-promo .expert-quote-promo__quote {
  font-size: 16px;
  line-height: 24px; }

.split-container__inner--30 .expert-quote-promo .fa-quote-left {
  font-size: 40px;
  top: -15px;
  left: -13px; }

.split-container__inner--30 .expert-quote-promo .expert-quote-promo__name,
.split-container__inner--30 .expert-quote-promo .expert-quote-promo__title {
  font-size: 14px;
  line-height: 24px; }

.split-container__inner--30 .expert-quote-promo .expert-quote-promo__image {
  block-size: auto;
  display: block;
  object-fit: cover;
  position: relative;
  width: 100%; }

@media (max-width: 1199px) {
  .split-container__inner--30 .expert-quote-promo .expert-quote-promo__color-block {
    top: -190px;
    margin-bottom: -190px;
    width: 100%;
    max-width: 100%;
    padding: 50px; }
  .split-container__inner--30 .expert-quote-promo .expert-quote-promo__quote {
    font-size: 16px;
    line-height: 24px; }
  .split-container__inner--30 .expert-quote-promo .fa-quote-left {
    display: block; }
  .split-container__inner--30 .expert-quote-promo .expert-quote-promo__name,
  .split-container__inner--30 .expert-quote-promo .expert-quote-promo__title {
    font-size: 16px;
    line-height: 24px; }
  .split-container__inner--30 .expert-quote-promo .expert-quote-promo__image {
    block-size: auto;
    display: block;
    object-fit: cover;
    position: relative;
    width: 100%; } }

@media (max-width: 799px) {
  .split-container__inner--30 .expert-quote-promo .expert-quote-promo__color-block {
    top: -100px;
    margin-bottom: -100px;
    width: 100%;
    max-width: 100%;
    padding: 20px; }
  .split-container__inner--30 .expert-quote-promo .expert-quote-promo__quote {
    font-size: 16px;
    line-height: 24px; }
  .split-container__inner--30 .expert-quote-promo .fa-quote-left {
    font-size: 40px;
    top: -15px;
    left: -13px; }
  .split-container__inner--30 .expert-quote-promo .expert-quote-promo__name,
  .split-container__inner--30 .expert-quote-promo .expert-quote-promo__title {
    font-size: 14px;
    line-height: 24px; }
  .split-container__inner--30 .expert-quote-promo .expert-quote-promo__image {
    block-size: auto;
    display: block;
    object-fit: cover;
    position: relative;
    width: 100%; } }

.split-container__inner--30 .expert-quote-promo .expert-quote-promo__quote {
  font-size: 16px;
  line-height: 24px; }

.split-container__inner--30 .expert-quote-promo .expert-quote-promo__name {
  font-size: 14px;
  line-height: 24px; }

.split-container__inner--30 .expert-quote-promo .expert-quote-promo__title {
  font-size: 14px;
  line-height: 24px; }

/* Color themes */
.expert-quote-promo.theme-light-blue .expert-quote-promo__color-block::after {
  background: rgba(0, 153, 238, 0.8); }

.expert-quote-promo.theme-blue .expert-quote-promo__color-block::after {
  background: rgba(0, 48, 135, 0.8); }

/* restyled */
.cta-without-image {
  padding: 40px; }
  @media (max-width: 1199px) {
    .cta-without-image {
      padding: 20px 40px; } }
  @media (max-width: 799px) {
    .cta-without-image {
      display: flex;
      flex-direction: column;
      padding: 20px; } }

.split-container__inner--30 .cta-without-image {
  flex-direction: column; }
  .split-container__inner--30 .cta-without-image .cta-without-image__header {
    font-size: 24px;
    line-height: 32px; }
  .split-container__inner--30 .cta-without-image .cta-without-image__flex-block {
    flex-direction: column; }
  .split-container__inner--30 .cta-without-image .cta-without-image__button {
    align-self: flex-start;
    flex: auto; }

.split-container__inner--50 .cta-without-image {
  flex-direction: column; }
  .split-container__inner--50 .cta-without-image .cta-without-image__flex-block {
    flex-direction: column; }
  .split-container__inner--50 .cta-without-image .cta-without-image__button {
    align-self: flex-start; }
  .split-container__inner--50 .cta-without-image .cta-without-image__header {
    font-size: 28px;
    line-height: 36px; }
  .split-container__inner--50 .cta-without-image .cta-without-image__summary {
    font-size: 16px;
    line-height: 24px; }
  .split-container__inner--50 .cta-without-image .cta-without-image__name, .split-container__inner--50 .cta-without-image .cta-without-image__title {
    font-size: 16px;
    line-height: 24px; }

.rail-container__content .cta-without-image-section .cta-without-image {
  padding: 20px;
  /* overly specific to override without impt */ }

@media only screen and (max-width: 799px) {
  .split-container__inner--30 .cta-without-image .cta-without-image__header {
    font-size: 24px;
    line-height: 32px; }
  .split-container__inner--30 .cta-with-image .cta-with-image__header {
    font-size: 24px;
    line-height: 32px; } }

.cta-without-image__header {
  font-size: 24px;
  line-height: 32px; }

.cta-without-image__type {
  display: block;
  width: 100%;
  margin-bottom: 10px; }

.cta-without-image__thumbnail {
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 5px; }
  .cta-without-image__thumbnail img {
    margin-right: 20px;
    max-width: 100%; }
  @media (max-width: 799px) {
    .cta-without-image__thumbnail {
      display: none; } }

.cta-without-image__flex-block {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1199px) {
    .cta-without-image__flex-block {
      flex-direction: column; } }

.cta-without-image__image-wrap-block {
  display: flex;
  flex-grow: 1;
  margin-right: 15px; }
  .cta-without-image__image-wrap-block .cta-without-image__thumbnail {
    float: left;
    align-self: flex-start; }
  .split-container__inner--50 .cta-without-image__image-wrap-block {
    display: block; }
    @media (max-width: 1199px) {
      .split-container__inner--50 .cta-without-image__image-wrap-block {
        display: flex; } }
  .split-container__inner--30 .cta-without-image__image-wrap-block {
    margin-right: 0; }

.cta-without-image__text-block {
  float: left;
  max-width: 70%; }
  .split-container__inner--50 .cta-without-image__text-block {
    float: none;
    max-width: none; }
  .split-container__inner--30 .cta-without-image__text-block {
    float: none;
    max-width: none; }
  @media (max-width: 1199px) {
    .cta-without-image__text-block {
      max-width: none; } }

.cta-without-image__header {
  margin-bottom: 10px;
  font-size: 34px;
  line-height: 42px;
  font-weight: 700; }
  @media (max-width: 1199px) {
    .cta-without-image__header {
      font-size: 34px;
      line-height: 42px; } }

.cta-without-image__summary {
  margin-bottom: 15px; }
  @media (max-width: 799px) {
    .cta-without-image__summary {
      font-size: 20px;
      line-height: 30px; } }
  .split-container__inner--50 .cta-without-image__summary {
    float: left; }

.cta-without-image__button {
  align-self: flex-end; }
  @media (max-width: 1199px) {
    .cta-without-image__button {
      display: inline-block;
      align-self: flex-start;
      width: auto; } }

/* Theme variations */
.cta-without-image.theme-grey {
  background-color: #f7f9f9; }
  .cta-without-image.theme-grey .cta-without-image__type {
    color: #a5a5a5; }
  .cta-without-image.theme-grey .cta-without-image__button {
    color: #fff;
    background-color: #0475BC; }
    .cta-without-image.theme-grey .cta-without-image__button:hover {
      color: #0475BC;
      background-color: #fff;
      outline: 2px solid #0475BC; }

.cta-without-image.theme-blue {
  background-color: #003087; }
  .cta-without-image.theme-blue .cta-without-image__type {
    color: #fff; }
  .cta-without-image.theme-blue .cta-without-image__header,
  .cta-without-image.theme-blue .cta-without-image__summary {
    color: #fff; }
  .cta-without-image.theme-blue .cta-without-image__button {
    color: #003087 !important;
    background-color: #fff !important; }
    .cta-without-image.theme-blue .cta-without-image__button:hover {
      color: #fff !important;
      background-color: #003087 !important;
      outline: 2px solid #fff !important; }
  .cta-without-image.theme-blue .cta-without-image__button .btn .cookie-policy-toggle {
    color: #003087;
    background-color: #fff; }
    .cta-without-image.theme-blue .cta-without-image__button .btn .cookie-policy-toggle:hover {
      color: #fff;
      background-color: #003087;
      outline: 2px solid #fff; }

.cta-without-image.theme-light-blue {
  background-color: #0475BC; }
  .cta-without-image.theme-light-blue .cta-without-image__type {
    color: #fff; }
  .cta-without-image.theme-light-blue .cta-without-image__header,
  .cta-without-image.theme-light-blue .cta-without-image__summary {
    color: #fff; }
  .cta-without-image.theme-light-blue .cta-without-image__button {
    color: #0475BC;
    background-color: #fff; }
    .cta-without-image.theme-light-blue .cta-without-image__button:hover {
      color: #fff;
      background-color: #0475BC;
      outline: 2px solid #fff; }

.cta-without-image.theme-white {
  display: flex;
  flex-direction: column;
  padding: 15px 20px 20px;
  background-color: #fff;
  border-top: 4px solid #d9d9d9;
  border-bottom: 2px solid #d9d9d9; }
  .cta-without-image.theme-white .cta-without-image__type {
    color: #a5a5a5; }
  .cta-without-image.theme-white .cta-without-image__flex-block {
    display: flex; }
  .cta-without-image.theme-white .cta-without-image__button {
    align-self: flex-end;
    flex-grow: 0;
    position: relative;
    font-size: 13px;
    height: 44px;
    color: #fff !important;
    background-color: #0475BC !important; }
    @media (max-width: 1199px) {
      .cta-without-image.theme-white .cta-without-image__button {
        width: auto;
        margin-bottom: 0;
        align-self: flex-end; } }
    .cta-without-image.theme-white .cta-without-image__button:hover {
      color: #0475BC !important;
      background-color: #fff !important;
      outline: 2px solid #0475BC !important; }

.cta-without-image__print-email {
  display: none; }

/* restyled */
.cta-with-image {
  position: relative;
  color: #fff;
  overflow: hidden; }

.cta-with-image__color-block {
  display: inline-block; }
  .cta-with-image__color-block::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 0; }

.cta-with-image__color-block div {
  position: relative;
  z-index: 1; }

.cta-with-image__header {
  display: block;
  margin-bottom: 20px;
  font-size: 34px;
  line-height: 42px;
  font-weight: bold;
  color: #fff; }

.cta-with-image__summary {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 30px;
  color: #fff; }

@media only screen and (max-width: 799px) {
  .cta-with-image .cta-with-image__header {
    font-size: 24px;
    line-height: 32px; }
  .cta-with-image .cta-with-image__summary {
    font-size: 18px;
    line-height: 24px; } }

.cta-with-image__name,
.cta-with-image__title {
  font-size: 16px;
  font-weight: 700; }

.cta-with-image__button {
  height: 44px;
  white-space: nowrap; }

a.cta-with-image__button.btn {
  color: #0475BC;
  background-color: #fff; }
  a.cta-with-image__button.btn:hover {
    color: #fff;
    background-color: #0475BC;
    outline: 2px solid #fff; }

.cta-with-image__flex-wrap {
  display: flex;
  justify-content: space-between; }

/*  Note : Had to use mixins for the below styles
    (instead of regular classes or placeholders)
    b/c you can't extend from within the media query
    mixin.
*/
/** 3 Style Versions: Large, Medium Small **/
/** (they don't just correspond to breakpoints) **/
/*  Only for components in main content container,
    at large breakpoint
*/
/*  For all components at medium breakpoint,
    and also in 50% container at large breakpoints
*/
/*  For all components at small breakpoint,
    and also in 30% container at large breakpoints
*/
/*  Applying the correct styles to the correct
    breakpoints and containers
*/
.cta-with-image {
  min-height: 300px; }
  .cta-with-image .cta-with-image__color-block {
    position: absolute;
    width: 55%;
    height: 100%;
    padding: 60px 30px 60px 60px; }
  .cta-with-image .cta-with-image__image {
    block-size: auto;
    display: block;
    object-fit: cover;
    position: relative;
    float: right;
    width: 50%; }
  .cta-with-image .cta-with-image__button {
    align-self: flex-end; }
  @media (max-width: 1199px) {
    .cta-with-image .cta-with-image__color-block {
      position: relative;
      top: -150px;
      margin-bottom: -150px;
      width: 100%;
      height: auto;
      max-width: 100%;
      padding: 50px; }
    .cta-with-image .cta-with-image__image {
      block-size: auto;
      display: block;
      object-fit: cover;
      position: relative;
      width: 100%; }
    .cta-with-image .cta-with-image__header {
      font-size: 34px;
      line-height: 42px;
      margin-bottom: 20px; }
    .cta-with-image .cta-with-image__summary {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 30px; }
    .cta-with-image .cta-with-image__name,
    .cta-with-image .cta-with-image__title {
      font-size: 16px;
      line-height: 24px; }
    .cta-with-image .cta-with-image__button {
      align-self: flex-end;
      width: auto;
      margin-top: 0;
      margin-bottom: 0; }
    .cta-with-image .cta-with-image__flex-wrap {
      flex-direction: row; } }
  @media (max-width: 799px) {
    .cta-with-image .cta-with-image__color-block {
      top: -75px;
      margin-bottom: -75px;
      width: 100%;
      max-width: 100%;
      padding: 25px; }
    .cta-with-image .cta-with-image__image {
      block-size: auto;
      display: block;
      object-fit: cover;
      position: relative;
      width: 100%; }
    .cta-with-image .cta-with-image__header {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 15px; }
    .cta-with-image .cta-with-image__summary {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 15px; }
    .cta-with-image .cta-with-image__name,
    .cta-with-image .cta-with-image__title {
      font-size: 16px;
      line-height: 24px; }
    .cta-with-image .cta-with-image__button {
      align-self: flex-start;
      width: auto;
      margin-top: 20px;
      margin-bottom: 0; }
    .cta-with-image .cta-with-image__flex-wrap {
      flex-direction: column; } }

@media only screen and (max-width: 799px) {
  .split-container__inner--50 .cta-with-image .cta-with-image__header {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 4px; }
  .split-container__inner--50 .cta-without-image .cta-without-image__header {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 4px; } }

.split-container__inner--50 .cta-with-image .cta-with-image__color-block {
  position: relative;
  top: -150px;
  margin-bottom: -150px;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding: 50px; }

.split-container__inner--50 .cta-with-image .cta-with-image__image {
  block-size: auto;
  display: block;
  object-fit: cover;
  position: relative;
  width: 100%; }

.split-container__inner--50 .cta-with-image .cta-with-image__header {
  font-size: 34px;
  line-height: 42px;
  margin-bottom: 20px; }

.split-container__inner--50 .cta-with-image .cta-with-image__summary {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px; }

.split-container__inner--50 .cta-with-image .cta-with-image__name,
.split-container__inner--50 .cta-with-image .cta-with-image__title {
  font-size: 16px;
  line-height: 24px; }

.split-container__inner--50 .cta-with-image .cta-with-image__button {
  align-self: flex-end;
  width: auto;
  margin-top: 0;
  margin-bottom: 0; }

.split-container__inner--50 .cta-with-image .cta-with-image__flex-wrap {
  flex-direction: row; }

@media (max-width: 799px) {
  .split-container__inner--50 .cta-with-image .cta-with-image__color-block {
    top: -75px;
    margin-bottom: -75px;
    width: 100%;
    max-width: 100%;
    padding: 25px; }
  .split-container__inner--50 .cta-with-image .cta-with-image__image {
    block-size: auto;
    display: block;
    object-fit: cover;
    position: relative;
    width: 100%; }
  .split-container__inner--50 .cta-with-image .cta-with-image__header {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px; }
  .split-container__inner--50 .cta-with-image .cta-with-image__summary {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px; }
  .split-container__inner--50 .cta-with-image .cta-with-image__name,
  .split-container__inner--50 .cta-with-image .cta-with-image__title {
    font-size: 16px;
    line-height: 24px; }
  .split-container__inner--50 .cta-with-image .cta-with-image__button {
    align-self: flex-start;
    width: auto;
    margin-top: 20px;
    margin-bottom: 0; }
  .split-container__inner--50 .cta-with-image .cta-with-image__flex-wrap {
    flex-direction: column; } }

.split-container__inner--50 .cta-with-image .cta-with-image__header {
  font-size: 24px;
  line-height: 32px; }

.split-container__inner--50 .cta-with-image .cta-with-image__summary {
  font-size: 16px;
  line-height: 24px; }

.split-container__inner--50 .cta-with-image .cta-with-image__name,
.split-container__inner--50 .cta-with-image .cta-with-image__title {
  font-size: 16px;
  line-height: 24px; }

.split-container__inner--30 .cta-with-image .cta-with-image__color-block {
  top: -75px;
  margin-bottom: -75px;
  width: 100%;
  max-width: 100%;
  padding: 25px; }

.split-container__inner--30 .cta-with-image .cta-with-image__image {
  block-size: auto;
  display: block;
  object-fit: cover;
  position: relative;
  width: 100%; }

.split-container__inner--30 .cta-with-image .cta-with-image__header {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 15px; }

.split-container__inner--30 .cta-with-image .cta-with-image__summary {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px; }

.split-container__inner--30 .cta-with-image .cta-with-image__name,
.split-container__inner--30 .cta-with-image .cta-with-image__title {
  font-size: 16px;
  line-height: 24px; }

.split-container__inner--30 .cta-with-image .cta-with-image__button {
  align-self: flex-start;
  width: auto;
  margin-top: 20px;
  margin-bottom: 0; }

.split-container__inner--30 .cta-with-image .cta-with-image__flex-wrap {
  flex-direction: column; }

.split-container__inner--30 .cta-with-image .cta-with-image__color-block {
  height: auto;
  position: relative; }

@media (max-width: 1199px) {
  .split-container__inner--30 .cta-with-image .cta-with-image__color-block {
    position: relative;
    top: -150px;
    margin-bottom: -150px;
    width: 100%;
    height: auto;
    max-width: 100%;
    padding: 50px; }
  .split-container__inner--30 .cta-with-image .cta-with-image__image {
    block-size: auto;
    display: block;
    object-fit: cover;
    position: relative;
    width: 100%; }
  .split-container__inner--30 .cta-with-image .cta-with-image__header {
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 20px; }
  .split-container__inner--30 .cta-with-image .cta-with-image__summary {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px; }
  .split-container__inner--30 .cta-with-image .cta-with-image__name,
  .split-container__inner--30 .cta-with-image .cta-with-image__title {
    font-size: 16px;
    line-height: 24px; }
  .split-container__inner--30 .cta-with-image .cta-with-image__button {
    align-self: flex-end;
    width: auto;
    margin-top: 0;
    margin-bottom: 0; }
  .split-container__inner--30 .cta-with-image .cta-with-image__flex-wrap {
    flex-direction: row; } }

@media (max-width: 799px) {
  .split-container__inner--30 .cta-with-image .cta-with-image__color-block {
    top: -75px;
    margin-bottom: -75px;
    width: 100%;
    max-width: 100%;
    padding: 25px; }
  .split-container__inner--30 .cta-with-image .cta-with-image__image {
    block-size: auto;
    display: block;
    object-fit: cover;
    position: relative;
    width: 100%; }
  .split-container__inner--30 .cta-with-image .cta-with-image__header {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 15px; }
  .split-container__inner--30 .cta-with-image .cta-with-image__summary {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px; }
  .split-container__inner--30 .cta-with-image .cta-with-image__name,
  .split-container__inner--30 .cta-with-image .cta-with-image__title {
    font-size: 16px;
    line-height: 24px; }
  .split-container__inner--30 .cta-with-image .cta-with-image__button {
    align-self: flex-start;
    width: auto;
    margin-top: 20px;
    margin-bottom: 0; }
  .split-container__inner--30 .cta-with-image .cta-with-image__flex-wrap {
    flex-direction: column; } }

.split-container__inner--30 .cta-with-image .cta-with-image__header {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 4px; }

.split-container__inner--30 .cta-with-image .cta-with-image__summary {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px; }

.split-container__inner--30 .cta-with-image .cta-with-image__name,
.split-container__inner--30 .cta-with-image .cta-with-image__title {
  font-size: 14px;
  line-height: 24px; }

/* Color themes */
.cta-with-image.theme-light-blue .cta-with-image__color-block::after {
  background: rgba(0, 104, 162, 0.8); }

.cta-with-image.theme-blue .cta-with-image__color-block::after {
  background: rgba(0, 48, 135, 0.8); }

.cta-with-image.theme-green .cta-with-image__color-block::after {
  background: rgba(84, 138, 46, 0.8); }

.cta-with-image.theme-orange .cta-with-image__color-block::after {
  background: rgba(128, 96, 0, 0.8); }

.cta-with-image.theme-teal .cta-with-image__color-block::after {
  background: rgba(0, 77, 77, 0.8); }

.cta-with-image.theme-maroon .cta-with-image__color-block::after {
  background: rgba(96, 32, 64, 0.8); }

/* restyled */
.search-container {
  margin-bottom: 40px; }

.search-box {
  width: 70%; }
  @media (max-width: 799px) {
    .search-box {
      width: 100%; } }

.search-box__input-container {
  position: relative; }

.search-box__input {
  font-size: 16px;
  line-height: 24px;
  height: 50px;
  width: 100%;
  border: 3px solid #d9d9d9; }
  @media (max-width: 799px) {
    .search-box__input {
      min-width: 0; } }

.search-box__button {
  border: none;
  background-color: transparent;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%); }
  .search-box__button .icon {
    height: 24px;
    width: 24px; }

/* restyled */
.search-item {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 30px;
  padding-bottom: 30px; }

.search-item__title {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 5px; }
  .search-item__title a {
    border: none; }

@media only screen and (max-width: 799px) {
  .search-item__title {
    font-size: 20px !important;
    line-height: 30px !important; }
  .search-item__authors {
    font-size: 16px;
    line-height: 24px; }
  .featured-person__bio {
    font-size: 16px;
    line-height: 24px; } }

.search-item__meta {
  margin-bottom: 5px; }

.search-item__image-link {
  border: none; }
  .search-item__image-link:hover {
    border: none; }

.search-item__image-container {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  margin-right: 25px;
  width: 150px; }
  .search-item__image-container::before {
    display: block;
    width: 100%;
    padding-top: 100%;
    content: ""; }
  .search-item__image-container > .content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  @media (max-width: 799px) {
    .search-item__image-container {
      display: none; } }

.search-item__image {
  max-width: 100%; }

.search-item__content {
  display: inline-block;
  vertical-align: top;
  width: calc(75% - 25px); }
  @media (max-width: 799px) {
    .search-item__content {
      width: 100%; } }

.search-item__content-type {
  color: #999999;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase; }

.search-item__subtitle {
  color: #222;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 15px; }

.search-item__body {
  font-size: 16px;
  line-height: 24px; }

/* restyled */
.search-results__sort-bar {
  margin-bottom: 20px; }

.search-results__sort-options {
  display: inline-block; }

.search-results__sorter {
  border-right: 1px solid #222;
  display: inline-block;
  list-style: none;
  margin-right: 15px;
  padding-right: 15px; }
  .search-results__sorter:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0; }

.search-results__load-more {
  text-align: center; }
  .search-results__load-more .btn {
    color: #fff;
    background-color: #0475BC;
    border-radius: 0px; }
    @media (hover: hover) {
      .search-results__load-more .btn:hover {
        color: #0475BC;
        background-color: #fff;
        outline: 2px solid #0475BC; } }
    .search-results__load-more .btn:focus {
      outline: 2px solid #0475BC; }

.search-results__status-sort {
  border-bottom: 4px solid #d9d9d9;
  margin-bottom: 50px; }

.search-results__sort-label {
  display: inline-block;
  text-transform: uppercase;
  line-height: 1.3; }
  .search-results__sort-label:hover {
    cursor: pointer; }
  .is-active .search-results__sort-label {
    border-bottom: 1px solid #78c143;
    font-weight: 700; }

.search-results__sort-by {
  color: #999999;
  font-weight: 700;
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
  text-transform: uppercase;
  vertical-align: middle; }

.search-results__sort-dir {
  display: inline-block;
  position: relative;
  top: 6px;
  vertical-align: middle; }
  .search-results__sort-dir button,
  .search-results__sort-dir svg {
    width: 24px;
    height: 24px; }
  .search-results__sort-dir button {
    color: #d9d9d9;
    display: block;
    text-align: center;
    background: transparent;
    border: 0;
    padding: 0;
    margin-top: -16px; }
    .search-results__sort-dir button.is-selected {
      color: #78c143; }
  .search-results__sort-dir svg {
    fill: currentColor; }

/* restyled */
.search-tags__container {
  max-width: 1280px;
  padding: 0 40px; }
  @media (max-width: 799px) {
    .search-tags__container {
      padding: 0 20px; } }

.search-tags {
  display: inline-block;
  padding-right: 100px;
  width: calc(100% - 288px); }
  @media (max-width: 1199px) {
    .search-tags {
      padding-right: 40px; } }
  @media (max-width: 799px) {
    .search-tags {
      margin-bottom: 20px;
      padding-right: 0;
      width: 100%; } }

.search-tags__clear-all {
  background-color: #78c143;
  border: none;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  letter-spacing: 3px;
  max-width: 288px;
  padding: 15px 40px;
  text-transform: uppercase;
  vertical-align: top; }
  .search-tags__clear-all:hover {
    background-color: #6cb03a; }
  @media (max-width: 799px) {
    .search-tags__clear-all {
      margin-bottom: 20px; } }

.search-tags__tag {
  background-color: #f7f9f9;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 15px;
  margin-right: 15px;
  padding: 10px 50px 10px 30px;
  position: relative; }
  .search-tags__tag:hover {
    background-color: #78c143;
    color: #fff;
    cursor: pointer; }
    .search-tags__tag:hover:before, .search-tags__tag:hover:after {
      background-color: #fff; }
  .search-tags__tag:last-child {
    margin-right: 0; }
  .search-tags__tag:after, .search-tags__tag:before {
    background-color: #78c143;
    content: '';
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 50%;
    height: 3px;
    width: 13px; }
  .search-tags__tag:before {
    transform: translateY(-50%) rotate(-45deg); }
  .search-tags__tag:after {
    transform: translateY(-50%) rotate(45deg); }

/* restyled */
.search .search-box {
  margin-bottom: rem(60);
  max-width: 40%; }

.search-full-width {
  background-color: #f4f5f6;
  margin-bottom: 40px; }

/* restyled */
/* print styles & overrides */
/* More info about @page: https://drafts.csswg.org/css-page-3 */
@page {
  margin: 1in 0.5in 0.5in;
  orphans: 4;
  widows: 3;
  size: A4; }

html, body {
  font-size: 16px !important;
  line-height: 24px !important;
  background-color: #FFF;
  height: auto !important;
  margin: 0;
  max-width: 1250px !important;
  padding: 0;
  width: 100% !important; }

.print-only {
  display: block; }

.profiler-results {
  display: none; }

.print-2-col {
  position: static !important; }
  .print-2-col .split-container__inner--70 {
    margin-right: 0 !important;
    max-width: calc(65% - 20px);
    float: left; }
  .print-2-col .split-container__inner--30 {
    margin-left: 0 !important;
    width: auto;
    max-width: calc(35% - 20px);
    padding-left: 20px !important;
    float: left;
    hyphens: none; }

/* Hide accessibility stuff */
.skip-link,
.screen-reader-only {
  display: none; }

/* Excerpt from the HTML5 Boilerplate print style sheet */
*,
*:before,
*:after,
*:first-letter,
p:first-line,
div:first-line,
blockquote:first-line,
li:first-line {
  background: transparent !important;
  color: #000 !important;
  /* Black prints faster: http://www.sanbeiji.com/archives/953 */
  box-shadow: none !important;
  text-shadow: none !important; }

/*  TODO (Once we have an h1):
    Print H1 content at top of page
    How-to @ https://www.smashingmagazine.com/2015/01/designing-for-print-with-css/
*/
/*  TODO (Nice to have ONLY!);
    Print a QR code at the bottom of the document that links to the CDM site
    How-to: https://www.smashingmagazine.com/2013/03/tips-and-tricks-for-print-style-sheets/#print-qr-codes-for-easy-url-references
*/
html, body {
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  font-size: 1em;
  line-height: 1.4; }

/*  Don't print headings at the end of a page */
h1, h2, h3, h4, h5 {
  page-break-after: avoid;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  orphans: 3;
  widows: 3; }

h1 {
  font-size: 36px;
  line-height: 38px; }

h2 {
  font-size: 32px;
  line-height: 38px; }

h3 {
  font-size: 28px;
  line-height: 36px; }

h4 {
  font-size: 20px;
  line-height: 20px; }

h5, h6 {
  font-size: 16px;
  line-height: 16px; }

p {
  font-size: 16px;
  line-height: 24px;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  margin-bottom: 15px;
  orphans: 3;
  widows: 4; }

.heading--section-title {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 5px;
  margin-bottom: 36px;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif;
  font-size: 32px;
  font-style: italic;
  font-weight: normal;
  line-height: 38px;
  color: #222; }
  .heading--section-title::after {
    position: absolute;
    top: 50%;
    display: inline-block;
    width: 100%;
    height: 1px;
    margin-left: 15px;
    background-color: #a5a5a5;
    content: ""; }

.heading--small, .event-promo__title, .title-and-summary__detail, .cta-without-image__type, .heading--small-subtle, .pullquote__attribution, .breadcrumb li, .breadcrumb__divider, .breadcrumb a {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase; }

.heading--small-subtle, .pullquote__attribution, .breadcrumb li, .breadcrumb__divider, .breadcrumb a {
  color: #707070; }

/*  Print title attribute after any abbreviations */
abbr[title]:after {
  content: " (" attr(title) ")"; }

/* header nav */
header .nav {
  display: none; }

/*  Prink link targets unless they're anchor links */
a {
  font-weight: 700;
  color: #000;
  text-decoration: none; }
  a[href^="#"]:after {
    content: "" !important; }
  a:after {
    content: " <" "www.cdmsmith.com" attr(href) ">" !important;
    font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif !important;
    font-size: 12px !important;
    line-height: 14px !important;
    margin-left: 10px !important;
    word-wrap: break-word !important;
    text-decoration: none !important;
    font-weight: 400 !important;
    text-transform: none !important; }

/* Underline links in paragraphs */
p a {
  text-decoration: underline; }

/* Style buttons more or less like links */
.btn {
  min-height: auto !important;
  padding: 0 !important;
  letter-spacing: 0 !important; }

button {
  display: none !important; }

header {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 10px;
  margin-bottom: 20px; }

.header--over-banner + main {
  top: 0; }

header .logo--print {
  position: relative; }
  header .logo--print img {
    display: block !important; }
  header .logo--print a {
    display: block;
    text-decoration: none;
    border-bottom: 0;
    position: relative; }
  header .logo--print a:after {
    content: "www.cdmsmith.com";
    position: relative;
    float: right;
    bottom: 10px;
    font-size: 14px; }

header .nav {
  display: none; }

main > .section,
main > .container {
  margin-bottom: 0; }

.section {
  max-width: 1250px;
  padding: 20px 0 !important; }

/* Remove dots on section titles */
.section-title {
  margin-bottom: 0; }
  .section-title__heading {
    padding-left: 0; }
  .section-title__block {
    display: none; }

/*  Don't show alt backgrounds */
.has-alt-background {
  background-color: #FFF !important; }

/* Replace component backgrounds with outlines */
.has-background {
  background-color: #FFF !important; }
  .has-background * {
    background-color: transparent !important; }

.is-full-width {
  margin-left: 0 !important;
  width: 100% !important; }

/* Normalize rail containers */
.rail-container__title {
  padding: 10px 0; }

.rail-container__content.has-alt-background {
  padding: 0; }

.split-container {
  float: none !important; }
  .split-container .split-container__inner {
    display: block !important; }

.split-container__inner--70,
.split-container__inner--30 {
  width: 100%; }

/** alphabetized by component class **/
.area-of-focus-section {
  display: none !important; }
  .print-2-col .area-of-focus-section {
    display: block !important; }

.area-of-focus__title {
  margin-bottom: 10px !important; }

.area-of-focus__list {
  list-style: none !important; }

.area-of-focus__list-item {
  margin-bottom: 5px !important; }

.print-2-col .area-of-focus {
  border: 10px solid #eff4f7;
  padding: 15px; }
  .print-2-col .area-of-focus__title {
    font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", serif;
    font-size: 22px;
    line-height: 1.2; }
  .print-2-col .area-of-focus__list-item {
    font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", serif;
    font-size: 13px;
    line-height: 1.7; }
    .print-2-col .area-of-focus__list-item a:after {
      display: none !important; }

.breadcrumb {
  font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", serif;
  list-style: none !important;
  margin: 0 !important; }
  .breadcrumb-wrapper {
    break-after: avoid;
    page-break-after: avoid;
    column-break-after: avoid;
    -moz-column-break-after: avoid;
    margin-bottom: 0 !important;
    padding-top: 0 !important; }
  .breadcrumb li {
    display: inline-block !important;
    font-size: 11px !important;
    text-transform: none !important;
    letter-spacing: 0; }
    .breadcrumb li:not(:last-of-type)::after {
      margin: 0 7px 0 10px !important; }
  .breadcrumb li a {
    font-weight: 700 !important;
    letter-spacing: 0;
    text-transform: none !important; }
    .breadcrumb li a:after {
      display: none !important; }
  .breadcrumb li.current a {
    border-bottom: 0 !important;
    font-weight: 700 !important; }
  .breadcrumb a {
    border-bottom: 2px solid #FFF !important;
    display: inline-block !important;
    font-size: 11px !important; }
    .breadcrumb a:before, .breadcrumb a:after {
      display: none; }
  .breadcrumb .breadcrumb__divider {
    margin: 0 !important; }

.copyright {
  font-family: "Times New Roman", serif; }

.cta-with-image {
  padding: 0 !important; }
  .cta-with-image__image {
    display: none !important; }
  .cta-with-image__color-block {
    top: 0 !important;
    margin-bottom: 0 !important;
    height: auto !important;
    background: transparent !important;
    width: 100% !important;
    position: static !important;
    padding: 0 !important; }
  .cta-with-image__button {
    align-self: flex-start !important;
    height: auto !important;
    width: auto !important; }
    .cta-with-image__button[href^="#"]:after {
      content: ""; }
    .cta-with-image__button:after {
      content: " <" "www.cdmsmith.com" attr(href) ">" !important;
      font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif !important;
      font-size: 12px !important;
      line-height: 14px !important;
      margin-left: 10px !important;
      word-wrap: break-word !important;
      text-decoration: none !important;
      font-weight: 400 !important;
      text-transform: none !important;
      width: auto !important;
      height: auto !important;
      position: relative !important; }

.cta-without-image {
  border: 0 !important;
  padding: 0 !important;
  page-break-inside: avoid; }
  .cta-without-image__thumbnail {
    display: none !important; }
  .cta-without-image__button {
    align-self: flex-start !important;
    height: auto !important;
    width: auto !important; }
    .cta-without-image__button[href^="#"]:after {
      content: ""; }
    .cta-without-image__button:after {
      content: " <" "www.cdmsmith.com" attr(href) ">" !important;
      font-family: "Stevie Sans W00", "HelveticaNeue", "Helvetica", "Arial", sans-serif !important !important;
      font-size: 12px !important;
      line-height: 14px !important;
      margin-left: 10px !important;
      word-wrap: break-word !important;
      text-decoration: none !important;
      font-weight: 400 !important;
      text-transform: none !important;
      width: auto !important;
      height: auto !important;
      position: relative !important; }
  .cta-without-image__header {
    font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", serif;
    font-size: 18px !important;
    line-height: 1.2 !important;
    margin-right: 20px; }
    .cta-without-image__header + .cta-without-image__print-email {
      display: block;
      font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", serif;
      font-size: 14px;
      font-weight: bold; }
  .cta-without-image__text-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .cta-without-image__summary {
    font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", serif;
    font-size: 12px !important;
    line-height: 1.7 !important;
    width: 100%; }
  .print-2-col + .cta-without-image-section {
    max-width: 1200px; }
    .print-2-col + .cta-without-image-section .cta-without-image {
      max-width: 65%;
      margin-left: 0; }
    .print-2-col + .cta-without-image-section .cta-without-image__button {
      display: none; }

.event-promo.has-alt-background {
  padding: 0 !important; }

.expert-quote-promo {
  display: flex; }
  .expert-quote-promo-section {
    break-inside: avoid; }
  .expert-quote-promo__image {
    display: none !important; }
  .expert-quote-promo__image--cropped {
    align-self: flex-start;
    display: block !important;
    margin-right: 30px;
    max-width: 33% !important;
    min-width: unset !important; }
  .expert-quote-promo__name {
    border-bottom: 0 !important;
    font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", serif;
    font-size: 14px !important; }
  .expert-quote-promo__color-block {
    top: 0 !important;
    left: 0 !important;
    padding: 0 !important;
    margin-bottom: 0 !important; }
    .expert-quote-promo__color-block::after {
      display: none !important; }
  .expert-quote-promo__quote {
    min-height: 55px !important; }
    .expert-quote-promo__quote div {
      display: inline-block !important;
      padding-top: 15px !important; }
    .expert-quote-promo__quote blockquote {
      font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", serif;
      font-weight: 700; }
  .expert-quote-promo__person-block a:after {
    display: none; }
  .expert-quote-promo .fa-quote-left {
    font-size: 36px !important;
    left: 0 !important;
    margin-bottom: 5px;
    position: relative !important;
    top: 0 !important; }
  .expert-quote-promo__title {
    font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", serif !important;
    font-size: 14px !important; }
  .print-2-col .expert-quote-promo {
    display: block !important;
    padding: 10px; }
    .print-2-col .expert-quote-promo img {
      max-width: 100% !important;
      margin-bottom: 10px; }
    .print-2-col .expert-quote-promo__quote blockquote {
      font-size: 13px; }

.focus-area-section {
  padding: 0 !important; }
  .focus-area-section__title {
    margin-bottom: 0 !important; }

.focus-items {
  margin-top: 10px !important; }

.focus-item-teaser {
  border-top-color: #CCC !important;
  padding: 20px 0 !important; }

.full-width-promo {
  height: auto !important;
  padding: 0 !important; }
  .full-width-promo__content > * {
    max-width: 100% !important; }
  .full-width-promo__divider-line {
    background-color: #CCC !important; }

.image__copyright {
  position: static !important;
  display: block !important;
  text-align: left !important; }

.logo-link img {
  max-width: 100px !important; }

.offices-ui__row {
  margin-top: 40px !important; }

.offices-ui__results-list {
  display: block !important; }

.opportunities__image-container {
  display: none !important; }

.opportunities__rte-container {
  position: relative !important;
  padding: 0 !important;
  display: block !important;
  margin: 0 !important; }

.opportunities__rte {
  max-width: 100% !important;
  padding: 0 !important;
  padding-top: 10px !important; }

.pullquote__quote {
  font-family: "Times New Roman", serif;
  font-weight: bold;
  max-width: 90%;
  font-size: 23px !important;
  line-height: 1.3 !important; }
  .pullquote__quote:before {
    font-size: 23px !important; }
  .pullquote__quote:after {
    font-size: 23px !important;
    margin-right: 5px !important; }

.pullquote-section {
  break-inside: avoid; }

.search-container {
  margin: 0 !important; }

.search-box {
  width: 100% !important; }

.search-box__button {
  display: none !important; }

.search-full-width {
  margin-bottom: 0 !important; }

.search-facets, .search-tags__clear-all {
  display: none !important; }

.search-tags__container {
  padding: 0 !important; }

.search-tags {
  width: 100% !important;
  padding: 0 !important; }

.search-tags__tag {
  border: 1px dashed #CCC;
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif !important; }

.search-item {
  margin-bottom: 10px !important;
  padding-bottom: 10px !important; }

.search-item__image-link {
  display: none !important; }

.search-item__title a::after {
  display: block !important;
  margin-top: 10px !important; }

.search-results__status-sort {
  margin-bottom: 10px !important; }

.statistics-section {
  padding: 0 !important; }

.statistic {
  display: none !important; }
  .statistic.statistic--print {
    display: block !important;
    flex-basis: 0 !important;
    flex-grow: 1 !important;
    flex-shrink: 1 !important;
    float: left !important;
    width: 33% !important;
    padding: 10px !important; }
  .statistic .statistic__super {
    font-size: 24px !important;
    padding: 0 !important;
    position: relative !important;
    top: -15px !important; }
  .statistic__counter-number {
    font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", serif !important;
    font-size: 46px !important;
    font-weight: bold;
    line-height: 1.2 !important; }
  .statistic__summary {
    margin-top: 0;
    font-size: 13px;
    font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", serif !important; }
  .statistic:before {
    display: none; }

.rte-section {
  font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", serif !important;
  font-size: 12px !important;
  line-height: 1.7;
  padding-top: 0 !important; }
  .rte-section:last-child {
    padding-bottom: 0 !important; }

.rte p,
.rte div {
  font-family: "Arial", sans-serif !important;
  font-size: 12px !important;
  line-height: 1.7; }

.rte a {
  border-bottom: 0 !important;
  text-decoration: none !important;
  font-weight: normal !important; }
  .rte a:after {
    display: none !important; }

.rte .section-title-style-snippet {
  font-family: "Times New Roman", serif !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: bold;
  line-height: 1.2 !important; }

.title-and-summary__detail {
  font-family: "Arial", sans-serif !important;
  font-size: 16px !important;
  letter-spacing: 0;
  margin-top: 0 !important;
  text-transform: none !important; }

.title-and-summary__divider-line {
  display: none !important; }

.title-and-summary__pipe {
  margin: 0 3px !important; }

.title-and-summary__summary {
  color: #808080 !important;
  font-family: "CDM Smith Light", "HelveticaNeue", "Helvetica", "Arial", sans-serif !important;
  font-size: 18px !important;
  font-weight: bold;
  letter-spacing: 0.14px; }
  .title-and-summary__summary:first-line, .title-and-summary__summary:first-letter {
    color: #808080 !important; }
  .print-2-col .title-and-summary__summary {
    font-weight: bold;
    font-size: 18px !important; }

.title-and-summary__title {
  font-family: "CDM Smith", "HelveticaNeue", "Helvetica", "Arial", serif !important;
  font-size: 22px !important;
  margin-bottom: 12px !important; }
  .title-and-summary__title:after {
    background-color: #bfbfbf !important;
    content: '';
    margin-top: 8px;
    display: block;
    height: 4px;
    width: 125px; }

.title-and-summary__type {
  background-color: #cddbe2 !important;
  border: none !important;
  font-family: "Arial", sans-serif;
  letter-spacing: 0;
  padding: 4px 10px !important; }

.title-summary-section {
  padding-top: 10px !important; }

.what-we-do {
  flex-direction: row !important; }
  .what-we-do__title::after {
    background-color: #CCC !important;
    margin: 10px auto !important; }
  .what-we-do__block {
    flex-grow: 1 !important;
    flex-shrink: 1 !important;
    margin-bottom: 0 !important; }

/* Hide banners */
.banner__outer-wrapper {
  display: none; }

.banner__image {
  background: none !important; }

.banner__large-notch {
  display: block;
  position: static;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

/* Hide related content components */
.teaser-block-section,
.related-experts-section,
.latest-news,
.news-list-section {
  display: none; }

/* Hide carousels */
.large-carousel-section,
.image-gallery-section,
.person-focus-section,
.people-river-section,
.featured-people-section {
  display: none; }

/* Hide floating & fullscreen stuff */
.back-to-top,
.cookie-warning,
.search-takeover,
.region-takeover,
.social-share {
  display: none; }

/* Hide interactive components */
.email-form-section,
.accordion-gallery-section,
.code-embed-section,
.downloads-button-section,
.offices-search__map-section {
  display: none; }

/* Hide navigation */
.left-page-navigation {
  display: none; }

/* Hide media components */
.video-section,
.image-section {
  display: none; }

/* Hide social */
.social-media-latest-news-section {
  display: none; }

/*  Force the printer to use web colors on webkit
    Note: No non-webkit equivalent
*/
@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact; } }

/* Make sure images don't bleed off the paper & aren't distorted */
img, figure {
  display: none;
  max-width: 100% !important;
  height: auto;
  page-break-inside: avoid !important; }

/* Hide background images */
*,
*::before,
*::after {
  background-image: none !important; }

/* Hide various interactive elements */
object, audio, embed, video {
  display: none; }

/* Tables: http://css-discuss.incutio.com/wiki/Printing_Tables */
table {
  max-width: 100% !important;
  page-break-after: avoid;
  page-break-inside: avoid; }
  table thead {
    display: table-header-group; }
  table tr {
    page-break-inside: avoid; }

footer {
  display: none; }

.copyright--print {
  display: block;
  margin-top: 15px; }

/*------------------------------------*\
    #BODY BASE STYLES
\*------------------------------------*/

html, body {
    min-height: 100%;
}

body {
    position: relative;
    overflow-x: hidden;
    font: 16px $font-cdmsmith;
    /*  Note: Font 100% means default browser font size.
        A user's browser settings could change this. */
    color: $color-text;
    overflow-y: auto;
    background: $color-white;
    font-variant-ligatures: none;
}

.modal-is-open,
.is-nav-open {
    overflow: hidden;
}

$title-and-summary-subtitle-color: $color-gray-87;
.title-and-summary {
  @extend %content-contained;
}


.title-and-summary__detail {
  @extend .heading--small;
  margin-bottom: 20px;
  margin-top: 12px;
  color: $color-brand-light-blue;
}

.title-and-summary__pipe {
  margin: 0 10px;
  color: $color-gray-15;
}

.title-and-summary__divider-line {
  display: block;
  width: 100px;
  height: 4px;
  margin: 15px 0;
  background-color: $color-brand-light-blue-font-rd !important;
}

.title-and-summary__summary {
  @extend .paragraph--splash;
  font-size: $font-size-small-3;
  line-height: $font-size-small-3-line;
  font-family: $font-cdmsmith !important;

  @include small-breakpoint {
    font-size: $font-size-small-2 !important;
    line-height: $font-size-small-2-line !important;
  }
}

.title-and-summary__type {
  background-color: $color-gray-15;
  color: $color-white;
  display: inline-block;
  font-size: $font-size-small;
  font-weight: 700;
  letter-spacing: 2px;
  padding: 4px 18px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.title-and-summary__subtitle {
  color: $title-and-summary-subtitle-color;
  font-family: $font-cdmsmith;
  font-size: $font-size-small-3;
  font-style: italic;
  font-weight: 400;
  line-height: $font-size-small-3-line;
  margin-top: 10px;
  margin-bottom: 15px;
}

.title-and-summary__h2Title
{
  display: block;
  font-family: $font-cdmsmith;
  font-size: $font-size-large;
  line-height: $font-size-large-line;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;

    @include small-breakpoint {
        font-size: $font-size-large-m;
        line-height: $font-size-large-m-line;
    }
}

h1.title-and-summary__title
{
	display: block;
	font-size: $font-size-large;
    line-height: $font-size-large-line;
	margin-left: 0;
	margin-right: 0;
	font-weight: bold;

    @include small-breakpoint {
        font-size: $font-size-large-m;
        line-height: $font-size-large-m-line;
    }
}

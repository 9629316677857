

.full-width-promo {
  position: relative;
  display: flex;
  align-items: center;
  height: 500px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 40px;

  @include small-breakpoint {
    height: 300px;
    padding: 20px;
  }

  &::before {
    background-image: linear-gradient(
      to left,
      transparent 0%,
      $color-transparent-blue 100%
    );
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    @include small-breakpoint {
      background-color: $color-transparent-blue;
      opacity: 0.7;
    }
  }
}

.full-width-promo__content {
  max-width: $max-content-width;
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: 1;

  > * {
    max-width: 50%;

    @include medium-breakpoint {
      max-width: 70%;
    }

    @include small-breakpoint {
      max-width: 100%;
    }
  }
}

.full-width-promo__headline {
  font-size: $font-size-med-3;
  line-height: $font-size-med-3-line;
  font-weight: 700;
  color: $color-white;

  @include small-breakpoint {
    font-size: $font-size-med;
    line-height: $font-size-med-line;
    width: 100%;
    padding: 0;
  }
}

.full-width-promo__divider-line {
  display: block;
  width: 100px;
  height: 4px;
  margin: 15px 0;
  background-color: $color-brand-teal;
}

.full-width-promo__picture {
  inset: 0;
  position: absolute;

  img {
    block-size: 100%;
    inline-size: 100%;
    object-fit: cover;
  }
}

.full-width-promo__summary {
  @extend .paragraph--splash;
  color: $color-white;
  font-size: $font-size-small-3;
  line-height: $font-size-small-3-line;
  font-family: $font-cdmsmith-sans !important;

  &.desktop {
    display: block;
    font-size : 18px;
  }

  &.mobile {
    display: none;
  }

  @include small-breakpoint {
    font-size: $font-size-small-2;
    line-height: $font-size-small-2-line;

    &.desktop {
      display: none;
    }

    &.mobile {
      display: block;
    }
  }
}

@media only screen and (max-width:799px) {
  .full-width-promo__headline{
      font-size: $font-size-med;
      line-height: $font-size-med-line;
  }

  .full-width-promo__summary{
      font-size: $font-size-small-2;
      line-height: $font-size-small-2-line;
  }
}

.full-width-promo__btn {
  margin-top: 24px;
}

a.full-width-promo__btn.btn.btn--white{
  color: $color-brand-light-blue-acc;
  background-color: $color-white;
  &:hover {
    color: $color-white;
    background-color: $color-brand-light-blue-acc;
    outline: 2px solid $color-white;
  }
}

/*  Force the printer to use web colors on webkit
    Note: No non-webkit equivalent
*/
@media print and (color) {
    * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
}

/* Make sure images don't bleed off the paper & aren't distorted */
img, figure {
    display: none;
    max-width: 100% !important;
    height: auto;
    page-break-inside : avoid !important;
}

/* Hide background images */
*,
*::before,
*::after {
    background-image: none !important;
}

/* Hide various interactive elements */
object, audio, embed, video {
    display: none;
}

/* Tables: http://css-discuss.incutio.com/wiki/Printing_Tables */
table {
    max-width: 100% !important;
    page-break-after : avoid;
    page-break-inside : avoid;

    thead {
        display: table-header-group;
    }

    tr {
        page-break-inside: avoid;
    }
}

/** alphabetized by component class **/

.area-of-focus {
    &-section {
      display: none !important;

      .print-2-col & {
        display: block !important;
      }
    }

    &__title {
        margin-bottom: 10px !important;
    }

    &__list {
        list-style: none !important;
    }

    &__list-item {
        margin-bottom: 5px !important;
    }

    .print-2-col & {
      border: 10px solid #eff4f7;
      padding: 15px;


      &__title {
        font-family: $font-cdmsmith-light;
        font-size: 22px;
        line-height: 1.2;
      }

      &__list-item {
        font-family: $font-cdmsmith-light;
        font-size: 13px;
        line-height: 1.7;

        a:after {
          display: none !important;
        }
      }
    }
}

.breadcrumb {
    font-family: $font-cdmsmith-light;
    list-style: none !important;
    margin: 0 !important;

    &-wrapper {
        break-after: avoid;
        page-break-after: avoid; // not supported by modern browsers
        column-break-after: avoid;
        -moz-column-break-after: avoid;
        margin-bottom: 0 !important;
        padding-top: 0 !important;
    }

    li {
        display: inline-block !important;
        font-size: 11px !important;
        text-transform: none !important;
        letter-spacing: 0;

        &:not(:last-of-type)::after {
            margin: 0 7px 0 10px !important;
        }
    }

    li a {
        font-weight: 700 !important;
        letter-spacing: 0;
        text-transform: none !important;

        &:after {
            display: none !important;
        }
    }

    li.current a {
        border-bottom: 0 !important;
        font-weight: 700 !important;
    }

    a {
        border-bottom: 2px solid #FFF !important;
        display: inline-block !important;
        font-size: 11px !important;

        &:before,
        &:after {
            display: none;
        }
    }

    .breadcrumb__divider {
      margin: 0 !important;
    }
}

.copyright {
  font-family: $font-secondary-serif;
}

.cta-with-image {
    padding: 0 !important;

    &__image {
        display: none !important;
    }

    &__color-block {
        top: 0 !important;
        margin-bottom: 0 !important;
        height: auto !important;
        background: transparent !important;
        width: 100% !important;
        position: static !important;
        padding: 0 !important;
    }

    &__button {
        align-self: flex-start !important;
        height: auto !important;
        width: auto !important;

        &[href^="#"]:after {
           content: "";
        }

        &:after {
            content: " <" $domain attr(href) ">" !important;
            font-family: $font-cdmsmith !important;
            font-size: 12px !important;
            line-height: 14px !important;
            margin-left: 10px !important;
            word-wrap: break-word !important;
            text-decoration: none !important;
            font-weight: 400 !important;
            text-transform: none !important;
            width: auto !important;
            height: auto !important;
            position: relative !important;
        }
    }
}

.cta-without-image {
    border: 0 !important;
    padding: 0 !important;
    page-break-inside: avoid;

    &__thumbnail {
        display: none !important;
    }

    &__button {
        align-self: flex-start !important;
        height: auto !important;
        width: auto !important;

        &[href^="#"]:after {
           content: "";
        }

        &:after {
            content: " <" $domain attr(href) ">" !important;
            font-family: $font-sans !important;
            font-size: 12px !important;
            line-height: 14px !important;
            margin-left: 10px !important;
            word-wrap: break-word !important;
            text-decoration: none !important;
            font-weight: 400 !important;
            text-transform: none !important;
            width: auto !important;
            height: auto !important;
            position: relative !important;
        }
    }

    &__header {
      font-family: $font-cdmsmith-light;
      font-size: 18px !important;
      line-height: 1.2 !important;
      margin-right: 20px;

      + .cta-without-image__print-email {
        display: block;
        font-family: $font-cdmsmith-light;
        font-size: 14px;
        font-weight: bold;
      }
    }

    &__text-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__summary {
      font-family: $font-cdmsmith-light;
      font-size: 12px !important;
      line-height: 1.7 !important;
      width: 100%;
    }

    &-section {
      .print-2-col + & {
        max-width: 1200px;

        .cta-without-image {
          max-width: 65%;
          margin-left: 0;
        }

        .cta-without-image__button {
          display: none;
        }
      }
    }
}

.event-promo {
    &.has-alt-background {
        padding: 0 !important;
    }
}

.expert-quote-promo {
    display: flex;

    &-section {
      break-inside: avoid;
    }

    &__image {
        display: none !important;
    }

    &__image--cropped {
        align-self: flex-start;
        display: block !important;
        margin-right: 30px;
        max-width: 33% !important;
        min-width: unset !important;
    }

    &__name {
        border-bottom: 0 !important;
        font-family: $font-cdmsmith-light;
        font-size: 14px !important;
    }

    &__color-block {
        top: 0 !important;
        left: 0 !important;
        padding: 0 !important;
        margin-bottom: 0 !important;

        &::after {
            display: none !important;
        }
    }

    &__quote {
        min-height: 55px !important;

        div {
            display: inline-block !important;
            padding-top: 15px !important;
        }

        blockquote {
          font-family: $font-cdmsmith-light;
          font-weight: 700;
        }
    }

    &__person-block {
      a:after {
        display: none;
      }
    }

    .fa-quote-left {
        font-size: 36px !important;
        left: 0 !important;
        margin-bottom: 5px;
        position: relative !important;
        top: 0 !important;
    }

    &__title {
      font-family: $font-cdmsmith-light !important;
      font-size: 14px !important;
    } //expert-quote-promo__title

    .print-2-col & {
      display: block !important;
      padding: 10px;

      img {
        max-width: 100% !important;
        margin-bottom: 10px;
      }

      &__quote {
        blockquote {
          font-size: 13px;
        }
      }
    }
}

.focus-area-section {
    padding: 0 !important;

    &__title {
        margin-bottom: 0 !important;
    }
}

.focus-items {
    margin-top: 10px !important;
}

.focus-item-teaser {
    border-top-color: $color-border !important;
    padding: 20px 0 !important;

    // &__button-space-saver,
    // &__button {
    //     display: none !important;
    // }
}

.full-width-promo {
    height: auto !important;
    padding: 0 !important;

    &__content > * {
        max-width: 100% !important;
    }

    &__divider-line {
        background-color: $color-border !important;
    }
}

.image {
    &__copyright {
        position: static !important;
        display: block !important;
        text-align: left !important;
    }
}

.logo {
  &-link {
    img {
      max-width: 100px !important;
    }
  }
}

.offices-ui__row {
    margin-top: 40px !important;
}

.offices-ui__results-list {
    display: block !important;
}

.opportunities {
    &__image-container {
        display: none !important;
    }

    &__rte-container {
        position: relative !important;
        padding: 0 !important;
        display: block !important;
        margin: 0 !important;
    }

    &__rte {
        max-width: 100% !important;
        padding: 0 !important;
        padding-top: 10px !important;
    }
}

.pullquote {
  &__quote {
    font-family: $font-secondary-serif;
    font-weight: bold;
    max-width: 90%;
    font-size: 23px !important;
    line-height: 1.3 !important;

    &:before {
      font-size: 23px !important;
    }

    &:after {
      font-size: 23px !important;
      margin-right: 5px !important;
    }
  } // pullquote__quote

  &-section {
    break-inside: avoid;
  }
}

.search {
    &-container {
        margin: 0 !important;
    }

    &-box {
        width: 100% !important;
    }

    &-box__button {
        display: none !important;
    }

    &-full-width {
        margin-bottom: 0 !important;
    }

    &-facets,
    &-tags__clear-all {
        display: none !important;
    }

    &-tags__container {
        padding: 0 !important;
    }

    &-tags {
        width: 100% !important;
        padding: 0 !important;
    }

    &-tags__tag {
        border: 1px dashed $color-border;
        font-family: $font-cdmsmith !important;
    }

    &-item {
        margin-bottom: 10px !important;
        padding-bottom: 10px !important;
    }

    &-item__image-link {
        display: none !important;
    }

    &-item__title a::after {
        display: block !important;
        margin-top: 10px !important;
    }

    &-results__status-sort {
        margin-bottom: 10px !important;
    }
}

.statistics-section {
  padding: 0 !important;
}

.statistic {
    display: none !important;

    &.statistic--print {
        display: block !important;
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        flex-shrink: 1 !important;
        float: left !important;
        width: 33% !important;
        padding: 10px !important;
    }

    .statistic__super {
        font-size: 24px !important;
        padding: 0 !important;
        position: relative !important;
        top: -15px !important;
    }

    &__counter-number {
      font-family: $font-cdmsmith-light !important;
      font-size: 46px !important;
      font-weight: bold;
      line-height: 1.2 !important;
    }

    &__summary {
      margin-top: 0;
      font-size: 13px;
      font-family: $font-cdmsmith-light !important;
    }

    &:before {
      display: none;
    }
}

.rte {
  &-section {
    font-family: $font-cdmsmith-light !important;
    font-size: 12px !important;
    line-height: 1.7;
    padding-top: 0 !important;

    &:last-child {
      padding-bottom: 0 !important;
    }
  } // rte-section

  p,
  div {
    font-family: $font-secondary-sans !important;
    font-size: 12px !important;
    line-height: 1.7;
  }

  a {
    border-bottom: 0 !important;
    text-decoration: none !important;
    font-weight: normal !important;

    &:after {
      display: none !important;
    }
  }

  .section-title-style-snippet {
    font-family: $font-secondary-serif !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: bold;
    line-height: 1.2 !important;
  }
}

.title-and-summary {
    &__detail {
      font-family: $font-secondary-sans !important;
      font-size: 16px !important;
      letter-spacing: 0;
      margin-top: 0 !important;
      text-transform: none !important;
    } //title-and-summary__detail

    &__divider-line {
      display: none !important;
    } // title-and-summary__divider-line

    &__pipe {
      margin: 0 3px !important;
    } // title-and-summary__pipe

    &__summary {
      color: #808080 !important;
      font-family: $font-cdmsmith-light-sans !important;
      font-size: 18px !important;
      font-weight: bold;
      letter-spacing: 0.14px;

      &:first-line,
      &:first-letter {
        color: #808080 !important;
      }

      .print-2-col & {
        // color: #000000 !important;
        font-weight: bold;
        font-size: 18px !important;

        // &:first-line,
        // &:first-letter {
        //   color: #000000 !important;
        // }
      }
    } // .title-and-summary__summary

    &__title {
        font-family: $font-cdmsmith !important;
        font-size: 22px !important;
        margin-bottom: 12px !important;

        &:after {
            background-color: #bfbfbf !important;
            content: '';
            margin-top: 8px;
            display: block;
            height: 4px;
            width: 125px;
        }
    } // title-and-summary__title

    &__type {
        background-color: #cddbe2 !important;
        border: none !important;
        font-family: $font-secondary-sans;
        letter-spacing: 0;
        padding: 4px 10px !important;
    } //title-and-summary__type
}

.title-summary-section {
  padding-top: 10px !important;
}

.what-we-do {
    flex-direction: row !important;

    &__title::after {
        background-color: $color-border !important;
        margin: 10px auto !important;
    }

    &__block {
        flex-grow: 1 !important;
        flex-shrink: 1 !important;
        margin-bottom: 0 !important;
    }
}
